<dpa-modal
  [modalClosable]="true"
  [modalOpen]="showRequestCloudAccountModal$ | async"
  [modalSize]="ModalSize.MD"
  (onModalClose)="closeRequestAccountModal()"
>
  <dpa-modal-title>
    {{ 'BROWNFIELD_NOTIFICATION.REQUEST_ACCOUNT_HEADING' | translate }}
  </dpa-modal-title>
  <div class="modal-body">
    <ng-container *ngIf="!isCspAccountRequestAlreadySubmitted">
      <dpa-request-cloud-account [showTitle]="false"></dpa-request-cloud-account>
    </ng-container>
    <span *ngIf="isCspAccountRequestAlreadySubmitted">
      {{ 'BROWNFIELD_NOTIFICATION.REQUEST_SUBMITTED_DESCRIPTION' | translate }}
    </span>
  </div>
  <div
    *ngIf="isCspAccountRequestAlreadySubmitted"
    class="modal-footer"
  >
    <button
      (click)="closeRequestAccountModal()"
      class="btn btn-primary"
    >
      {{ 'COMMON_ACTIONS.CLOSE' | translate }}
    </button>
  </div>
</dpa-modal>
