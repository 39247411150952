/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { GenericItem, GlobalHeaderService, ServiceLink, SortOn } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, fromEvent, Observable, Subscription, timer } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

import { UserActions, UserService } from '@dpa-shared-merlot';
import { I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import {
  AuthService,
  IntegratedServicesSelectors,
  NavigationMenuSelectors,
  OrgSelectors,
  RbacSelectors,
  UserPreferenceActions,
  UserPreferenceCommonSelectors,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceSelectors,
} from '@ws1c/intelligence-core';
import { CLARITY_TOOLTIP_POSITION, COLUMN_NAMES, IntelOrg, Org, ROUTE_NAMES, UserAccount } from '@ws1c/intelligence-models';
import {
  IntelNotification,
  NotificationCenterActions,
  NotificationCenterSelectors,
  NotificationConstants,
  NotificationViewType,
} from '@ws1c/notification';

/**
 * HeaderComponent
 *
 * @export
 * @class HeaderComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() public brandingTemplate: TemplateRef<any>;

  public isAuthenticated$: Observable<boolean>;
  public isRemediationMode$: Observable<boolean>;
  public showBaseHeaderActions$: Observable<boolean>;
  public showNotificationsHeaderAction$: Observable<boolean>;
  public showGlobalSearch$: Observable<boolean>;
  public serviceLinks$: Observable<ServiceLink[]>;
  public isOnboardingComplete$: Observable<boolean>;
  public isCspLoggedInUser$: Observable<boolean>;
  public orgBaseUrl$: Observable<string>;
  public activeUser$: Observable<GenericItem>;
  public activeOrg$: Observable<Org>;
  public notifications$: Observable<IntelNotification[]>;
  public unreadNotificationsCount$: Observable<number>;
  public isRulesEnabled$: Observable<boolean>;
  public isLoadingNotifications$: Observable<boolean>;
  public userOrgs$: Observable<IntelOrg[]>;

  public hasUserScopes: boolean;
  public sortByList: SortOn[];
  public sub: Subscription = new Subscription();

  public readonly VMW_DOCS_PRODUCT_NAME = 'VMware Workspace ONE Intelligence';
  public readonly ROUTE_NAMES = ROUTE_NAMES;
  public readonly CLARITY_TOOLTIP_POSITION = CLARITY_TOOLTIP_POSITION;

  /**
   * Creates an instance of HeaderComponent.
   * @param {AuthService} auth
   * @param {Store} store
   * @param {GlobalHeaderService} headerService
   * @param {I18NService} i18nService
   * @param {RouterExtensions} routerExt
   * @param {UserService} userService
   * @memberof HeaderComponent
   */
  constructor(
    private auth: AuthService,
    private store: Store,
    private headerService: GlobalHeaderService,
    private i18nService: I18NService,
    private routerExt: RouterExtensions,
    private userService: UserService,
  ) {
    this.isAuthenticated$ = this.auth.isAuthenticated$;
    this.isRemediationMode$ = this.store.select(UserPreferenceSelectors.isRemediationMode);
    this.showBaseHeaderActions$ = this.store.select(NavigationMenuSelectors.showBaseHeaderActions);
    this.showNotificationsHeaderAction$ = combineLatest([
      this.auth.isNavigationRestricted$,
      this.store.select(UserPreferenceFeatureControlsSelectors.isNotificationEnabled),
    ]).pipe(map(([isNavigationRestricted, isNotificationEnabled]: boolean[]) => !isNavigationRestricted && isNotificationEnabled));
    this.showGlobalSearch$ = combineLatest([this.auth.isNavigationRestricted$, this.store.select(OrgSelectors.isGlobalSearchAllowed)]).pipe(
      map(([isNavigationRestricted, isGlobalSearchAllowed]: boolean[]) => !isNavigationRestricted && isGlobalSearchAllowed),
    );
    this.isOnboardingComplete$ = this.store.select(OrgSelectors.isOnboardingComplete);
    this.isCspLoggedInUser$ = this.store.select(UserPreferenceSelectors.isCspLoggedInUser);
    this.orgBaseUrl$ = this.store.select(UserPreferenceSelectors.getOrgBaseUrl);
    this.serviceLinks$ = this.store.select(IntegratedServicesSelectors.getAvailableServiceLinks);
    this.isRulesEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isNotificationRulesEnabled);
    this.userOrgs$ = this.store.select(UserPreferenceCommonSelectors.userOrgs);
    this.isLoadingNotifications$ = this.store.select(NotificationCenterSelectors.isLoadingBellTrayNotifications);
    this.notifications$ = combineLatest([
      this.store.select(NotificationCenterSelectors.bellTrayNotifications),
      this.store.select(NotificationCenterSelectors.readNotificationsMap),
    ]).pipe(
      map(([notifications, readNotificationMap]: [IntelNotification[], Record<string, boolean>]) => {
        return notifications.map(
          (notification: IntelNotification) =>
            new IntelNotification({
              ...notification,
              read: readNotificationMap[notification.id],
            }),
        );
      }),
    );
    this.unreadNotificationsCount$ = this.store.select(NotificationCenterSelectors.totalUnseenNotifications);
    this.activeUser$ = this.store.select(UserPreferenceSelectors.getUserAccountInfo).pipe(
      filter(Boolean),
      map((user: UserAccount) => ({
        id: user.id,
        label: user.fullName,
      })),
    );
    this.activeOrg$ = this.store.select(UserPreferenceCommonSelectors.getOrgInfo);
    this.sortByList = [
      new SortOn({
        by: COLUMN_NAMES.byName.severity_priority,
        label: this.i18nService.translate('GLOBAL_HEADER.SEVERITY_DESCENDING'),
        reverse: true,
      }),
      new SortOn({
        by: COLUMN_NAMES.byName.severity_priority,
        label: this.i18nService.translate('GLOBAL_HEADER.SEVERITY_ASCENDING'),
        reverse: false,
      }),
      new SortOn({
        by: COLUMN_NAMES.byName.created_at,
        label: this.i18nService.translate('GLOBAL_HEADER.DATE_DESCENDING'),
        reverse: true,
      }),
      new SortOn({
        by: COLUMN_NAMES.byName.created_at,
        label: this.i18nService.translate('GLOBAL_HEADER.DATE_ASCENDING'),
        reverse: false,
      }),
    ];
  }

  /**
   * ngOnInit
   * @memberof HeaderComponent
   */
  public ngOnInit() {
    this.sub.add(
      this.store.select(RbacSelectors.hasUserScopes).subscribe((hasUserScopes: boolean) => {
        this.hasUserScopes = hasUserScopes;
      }),
    );
    this.sub.add(
      // Subscribe to a new observable everytime the window focus state changes
      combineLatest([
        this.showNotificationsHeaderAction$,
        fromEvent(window, 'blur').pipe(startWith(undefined)),
        fromEvent(window, 'focus').pipe(startWith(undefined)),
      ])
        .pipe(
          filter(([showNotificationsAction]: [boolean, Event, Event]) => showNotificationsAction),
          switchMap(() => {
            return document.hasFocus() ? timer(0, NotificationConstants.NOTIFICATION_POLLING_INTERVAL) : EMPTY;
          }),
        )
        .subscribe(() => {
          this.store.dispatch(NotificationCenterActions.getUnseenNotificationsCount());
        }),
    );
    this.sub.add(
      this.isCspLoggedInUser$.pipe(filter(Boolean)).subscribe(() => {
        this.store.dispatch(UserPreferenceActions.getUserOrgs());
      }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof HeaderComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * loadNotifications
   * @memberof HeaderComponent
   */
  public loadNotifications() {
    if (this.hasUserScopes) {
      this.store.dispatch(NotificationCenterActions.getBellTrayNotifications());
    }
  }

  /**
   * Check whether user is authenticated or not
   *
   * @returns {boolean}
   * @memberof HeaderComponent
   */
  public isLoggedIn(): boolean {
    return this.auth.isAuthenticated();
  }

  /**
   * Handle logout request
   * @memberof HeaderComponent
   */
  public onLogout() {
    this.store.dispatch(UserActions.logout());
  }

  /**
   * onDismiss
   * @param {IntelNotification} notification
   * @memberof HeaderComponent
   */
  public onDismiss(notification: IntelNotification) {
    this.store.dispatch(
      NotificationCenterActions.dismissNotifications({
        notificationIds: [notification.id],
        view: NotificationViewType.BELL_TRAY,
      }),
    );
  }

  /**
   * toggleRead
   * @param {IntelNotification} notification
   * @memberof HeaderComponent
   */
  public toggleRead(notification: IntelNotification) {
    this.store.dispatch(
      NotificationCenterActions.toggleReadNotifications({
        notificationIds: [notification.id],
        read: !notification.read,
      }),
    );
  }

  /**
   * onSortChange
   * @param {SortOn} sortOn
   * @memberof HeaderComponent
   */
  public onSortChange(sortOn: SortOn) {
    this.store.dispatch(
      NotificationCenterActions.queryBellTrayNotifications({
        sortOns: [sortOn],
      }),
    );
  }

  /**
   * markAllAsRead
   * @param {IntelNotification[]} notifications
   * @memberof HeaderComponent
   */
  public markAllAsRead(notifications: IntelNotification[]) {
    this.store.dispatch(
      NotificationCenterActions.toggleReadNotifications({
        notificationIds: notifications.map((notification: IntelNotification) => notification?.id),
        read: true,
      }),
    );
  }

  /**
   * onDismissAll
   * @param {IntelNotification[]} notifications
   * @memberof HeaderComponent
   */
  public onDismissAll(notifications: IntelNotification[]) {
    this.store.dispatch(
      NotificationCenterActions.dismissNotifications({
        notificationIds: notifications.map((notification: IntelNotification) => notification?.id),
        view: NotificationViewType.BELL_TRAY,
      }),
    );
  }

  /**
   * onNotificationAction
   * @param {IntelNotification} notification
   * @memberof HeaderComponent
   */
  public onNotificationAction(notification: IntelNotification) {
    // if internal link then close side panel
    if (notification.actionPage) {
      this.headerService.hideActivePanel();
    }
    this.store.dispatch(
      NotificationCenterActions.openNotificationActionPageOrUrl({
        notification,
      }),
    );
  }

  /**
   * onViewNotification
   * @param {IntelNotification} notification
   * @memberof HeaderComponent
   */
  public onViewNotification(notification: IntelNotification) {
    this.headerService.hideActivePanel();
    this.routerExt.navigate([ROUTE_NAMES.NOTIFICATION.CENTER, notification?.id]);
  }

  /**
   * changeOrg
   * @param {IntelOrg} org
   * @memberof HeaderComponent
   */
  public changeOrg(org: IntelOrg) {
    this.userService.changeOrg(org);
  }
}
