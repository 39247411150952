/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { NavigationMenuSelectors } from '@ws1c/intelligence-core';
import { MenuItem, NavigationMenuGroup, NavigationMenuItem } from '@ws1c/intelligence-models';

/**
 * MarketplaceMenuComponent
 * @export
 * @class MarketplaceMenuComponent
 */
@Component({
  selector: 'dpa-marketplace-menu',
  templateUrl: 'marketplace-menu.component.html',
  styleUrls: ['marketplace-menu.component.scss'],
})
export class MarketplaceMenuComponent {
  public defaultMarketplaceMenuItemRoute$: Observable<string>;
  public rootMenuItems$: Observable<MenuItem[]>;
  public integrationsMenuItems$: Observable<MenuItem[]>;
  public solutionsMenuItems$: Observable<MenuItem[]>;
  public templatesMenuItems$: Observable<MenuItem[]>;

  public readonly NavigationMenuItem = NavigationMenuItem;
  public readonly NavigationMenuGroup = NavigationMenuGroup;

  /**
   * Creates an instance of MarketplaceMenuComponent.
   * @param {Store<MerlotState>} store - Merlot Store observable instance
   * @memberof MarketplaceMenuComponent
   */
  constructor(private store: Store<MerlotState>) {
    this.rootMenuItems$ = this.store.select(NavigationMenuSelectors.getVisibleMenuItems(NavigationMenuItem.MARKETPLACE));
    this.integrationsMenuItems$ = this.store.select(
      NavigationMenuSelectors.getVisibleMenuItems(NavigationMenuItem.MARKETPLACE_INTEGRATIONS),
    );
    this.solutionsMenuItems$ = this.store.select(NavigationMenuSelectors.getVisibleMenuItems(NavigationMenuItem.MARKETPLACE_SOLUTIONS));
    this.templatesMenuItems$ = this.store.select(NavigationMenuSelectors.getVisibleMenuItems(NavigationMenuItem.MARKETPLACE_TEMPLATES));

    this.defaultMarketplaceMenuItemRoute$ = this.store.select(NavigationMenuSelectors.defaultMarketplaceMenuItemRoute);
  }
}
