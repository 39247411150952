/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';

import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { CookiePolicyBannerComponent } from './cookie-policy-banner.component';

/**
 * CookiePolicyBannerModule
 * @exports CookiePolicyBannerModule
 */
@NgModule({
  declarations: [CookiePolicyBannerComponent],
  imports: [IntelligenceCommonModule],
  exports: [CookiePolicyBannerComponent],
})
export class CookiePolicyBannerModule {}
