/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { NavigationMenuSelectors } from '@ws1c/intelligence-core';
import { MenuItem, NavigationMenuGroup } from '@ws1c/intelligence-models';

/**
 * Notifications Menu Component
 *
 * @export
 * @class NotificationMenuComponent
 */
@Component({
  selector: 'dpa-notification-menu',
  templateUrl: 'notification-menu.component.html',
  styleUrls: ['notification-menu.component.scss'],
})
export class NotificationMenuComponent {
  public availableNotificationMenuItems$: Observable<MenuItem[]>;
  public readonly NavigationMenuGroup = NavigationMenuGroup;

  /**
   * Creates an instance of NotificationMenuComponent.
   * @param {Store<MerlotState>} store - Merlot Store observable instance
   * @memberof NotificationMenuComponent
   */
  constructor(private store: Store<MerlotState>) {
    this.availableNotificationMenuItems$ = this.store.select(NavigationMenuSelectors.availableNotificationMenuItems);
  }
}
