/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';
import { IntelligenceCommonModule } from '@ws1c/intelligence-common';

import { NotificationBaseModule, NotificationTranslationModule } from '@ws1c/notification';
import { BannerNotificationComponent } from './banner-notification.component';

/**
 * BannerNotificationModule
 *
 * @export
 * @class BannerNotificationModule
 */
@NgModule({
  declarations: [BannerNotificationComponent],
  imports: [IntelligenceCommonModule, NotificationBaseModule, NotificationTranslationModule],
  exports: [BannerNotificationComponent],
})
export class BannerNotificationModule {}
