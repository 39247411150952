/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { FuzzySubgroupConfig } from '@dpa/ui-common';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { GlobalSearchActions, GlobalSearchSelectors } from '@dpa-shared-merlot';
import { DashboardActions } from '@ws1c/intelligence-core/store/dashboard/dashboard.actions';
import { DashboardSelectors } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import { Category, GlobalSearchCategoryState, GlobalSearchWidget, WidgetDetailDefinition } from '@ws1c/intelligence-models';

/**
 * WidgetSearchCategoryState
 * @export
 * @class WidgetSearchCategoryState
 * @implements {GlobalSearchCategoryState<GlobalSearchWidget>}
 */
@Injectable()
export class WidgetSearchCategoryState implements GlobalSearchCategoryState<GlobalSearchWidget> {
  public subgroupConfig$: Observable<FuzzySubgroupConfig>;
  public isLoading$: Observable<boolean>;
  public items$: Observable<GlobalSearchWidget[]>;
  public categoryLabelsByName: any = {};

  /**
   * constructor
   * @param {Store} store
   * @memberof WidgetSearchCategoryState
   */
  constructor(private store: Store) {
    this.items$ = this.store.select(GlobalSearchSelectors.getWidgetsData);
    this.subgroupConfig$ = this.store.select(DashboardSelectors.getDashboardSubgroupConfig);
    // delay(0) is added to fix the ExpressionChangedAfterItHasBeenCheckedError which occurs when the two or more components
    // listen to same observable and try to update the UI
    this.isLoading$ = this.store.pipe(delay(0), select(GlobalSearchSelectors.isGlobalSearchLoading));
    this.store.select(DashboardSelectors.getDashboardCategoriesState).subscribe((categories: Category[]) => {
      categories.forEach((cat: Category) => {
        this.categoryLabelsByName[cat.name] = cat.label;
      });
    });
  }

  /**
   * onSearch
   * @param {string} query
   * @memberof WidgetSearchCategoryState
   */
  public onSearch(query: string) {
    this.store.dispatch(GlobalSearchActions.searchWidgets({ query: query || 'a' }));
  }

  /**
   * onSelectItem
   * @param {GlobalSearchWidget} widget
   * @memberof WidgetSearchCategoryState
   */
  public onSelectItem(widget: GlobalSearchWidget) {
    const widgetDetailDefinition = new WidgetDetailDefinition({
      trendDefinition: widget.trend.trendDefinition,
      isCrossCategory: widget.trend.trendDefinition.isCrossEntityOrIntegration,
    });
    this.store.dispatch(
      DashboardActions.goToCustomWidgetDetailPage({
        widgetDetailPage: {
          widgetId: widget.id,
          dashboardId: widget.dashboardId,
          widgetDetailDefinition,
        },
      }),
    );
  }

  /**
   * keyBy
   * @param {GlobalSearchWidget} widget
   * @returns {string}
   * @memberof WidgetSearchCategoryState
   */
  public keyBy(widget: GlobalSearchWidget): string {
    return `${widget.name} - ${widget.description}`;
  }
}
