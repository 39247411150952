<dpa-modal
  *ngIf="introModalOpen$ | async"
  [modalOpen]="introModalOpen$ | async"
  [modalClosable]="true"
  [modalSize]="ModalSize.XL"
  (onModalClose)="closeIntroModal()"
>
  <dpa-modal-title
    *ngIf="introModalMode === INTRO_MODAL_MODE.ACTIVATE_TRIAL"
    class="clr-flex-items-sm-middle"
  >
    {{ 'INTRO_MODAL.WELCOME_TO_WS1_INTELLEGENCE' | translate }}
  </dpa-modal-title>
  <div class="modal-body">
    <ng-container
      *ngIf="!isFetchingTrialUserContactDetails; else loading"
      [ngSwitch]="introModalMode"
    >
      <dpa-start-trial
        *ngSwitchCase="INTRO_MODAL_MODE.ACTIVATE_TRIAL"
        [trialUserContactDetails]="trialUserContactDetails$ | async"
        (onFormInit)="onTrialFormInit($event)"
      ></dpa-start-trial>
      <div
        *ngSwitchCase="INTRO_MODAL_MODE.ACTIVATING_TRIAL"
        class="activating-trial-wrapper"
      >
        <div class="trial-animation">
          <div
            dpaAnimateSvg
            [animateOptions]="trialAnimationConfig"
            class="animation-container"
          ></div>
        </div>
        <p class="mt-x0">
          {{ 'TRIAL.ACTIVATING_WORKSPACE_ONE_INTELLIGENCE_TRIAL' | translate }}
        </p>
      </div>
      <div
        *ngSwitchCase="INTRO_MODAL_MODE.ACTIVATED_TRIAL"
        class="activated-trial-wrapper"
      >
        <cds-icon
          class="is-solid success"
          shape="check-circle"
          size="80"
        ></cds-icon>
        <p class="mt-x8">
          {{ 'TRIAL.WORKSPACE_ONE_INTELLIGENCE_TRIAL_ACTIVATED_DESCRIPTION' | translate }}
        </p>
        <button
          (click)="closeIntroModal()"
          class="btn btn-primary mt-x9 get-started-btn"
        >
          {{ 'COMMON_ACTIONS.GET_STARTED' | translate }}
        </button>
      </div>
    </ng-container>
    <ng-template #loading>
      <div class="spinner-container-centered">
        <div class="spinner"></div>
      </div>
    </ng-template>
  </div>
  <div
    *ngIf="!isFetchingTrialUserContactDetails && introModalMode === INTRO_MODAL_MODE.ACTIVATE_TRIAL"
    class="modal-footer"
  >
    <button
      (click)="closeIntroModal()"
      class="btn btn-outline"
    >
      {{ 'TRIAL_BANNER.NO_THANKS' | translate }}
    </button>
    <button
      [disabled]="isPrimaryTrialButtonDisabled()"
      (click)="primaryTrialSubmit()"
      class="btn btn-primary"
    >
      {{ 'COMMON_ACTIONS.ACCEPT' | translate }}
    </button>
  </div>
</dpa-modal>
