/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { DateTimeFormat, GlobalSearchChangeEvent } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';

import {
  AppApteligentSearchCategoryState,
  AppSearchCategoryState,
  AutomationSearchCategoryState,
  DashboardSearchCategoryState,
  DeviceSearchCategoryState,
  OsSearchCategoryState,
  ReportSearchCategoryState,
  UserSearchCategoryState,
  WidgetSearchCategoryState,
} from '@dpa-components/global-header-search/search-category-state';
import { FeatureControl } from '@ws1c/intelligence-common';
import {
  FeatureSelectors,
  UserPreferenceCommonSelectors,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceUIPreferenceSelectors,
} from '@ws1c/intelligence-core';
import { FeatureControls, GlobalSearchCategory, GlobalSearchCategoryState } from '@ws1c/intelligence-models';

/**
 * Shows the Global search on the header
 * @export
 * @class GlobalHeaderSearchComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-global-header-search',
  templateUrl: 'global-header-search.component.html',
  styleUrls: ['global-header-search.component.scss'],
})
export class GlobalHeaderSearchComponent implements OnInit, OnDestroy {
  public showSearchField: boolean;
  public visibleCategories: Set<GlobalSearchCategory> = new Set<GlobalSearchCategory>();
  public activeCategory: GlobalSearchCategory = GlobalSearchCategory.APP;
  public sub: Subscription = new Subscription();

  public readonly stateByCategory: Record<GlobalSearchCategory, GlobalSearchCategoryState<any>> = {
    [GlobalSearchCategory.APP_APTELIGENT]: inject(AppApteligentSearchCategoryState),
    [GlobalSearchCategory.APP]: inject(AppSearchCategoryState),
    [GlobalSearchCategory.AUTOMATION]: inject(AutomationSearchCategoryState),
    [GlobalSearchCategory.DASHBOARD]: inject(DashboardSearchCategoryState),
    [GlobalSearchCategory.DEVICE]: inject(DeviceSearchCategoryState),
    [GlobalSearchCategory.OS]: inject(OsSearchCategoryState),
    [GlobalSearchCategory.REPORT]: inject(ReportSearchCategoryState),
    [GlobalSearchCategory.USER]: inject(UserSearchCategoryState),
    [GlobalSearchCategory.WIDGET]: inject(WidgetSearchCategoryState),
  } as const;
  public readonly GlobalSearchCategory = GlobalSearchCategory;
  public readonly DateTimeFormat = DateTimeFormat;

  private featureControls: FeatureControls = {};

  /**
   * constructor
   * @param {Store} store
   * @memberof GlobalHeaderSearchComponent
   */
  constructor(private store: Store) {}

  /**
   * ngOnInit
   * @memberof GlobalHeaderSearchComponent
   */
  public ngOnInit() {
    this.sub.add(
      this.store.select(UserPreferenceCommonSelectors.getFeatureControls).subscribe((featureControls: FeatureControls) => {
        this.featureControls = featureControls;
      }),
    );
    this.sub.add(
      combineLatest([
        this.store.select(FeatureSelectors.hasDashboardReadOnlyPerm),
        this.store.select(FeatureSelectors.hasDashboardWidgetReadOnlyPerm),
        this.store.select(FeatureSelectors.hasDashboardManagePerm),
        this.store.select(FeatureSelectors.hasContentSearchReadOnlyPerm),
        this.store.select(FeatureSelectors.hasAutomationReadOnlyPerm),
        this.store.select(FeatureSelectors.hasReportReadOnlyPerm),
        this.store.select(FeatureSelectors.hasReportManagePerm),
        this.store.select(UserPreferenceFeatureControlsSelectors.isStandaloneApteligentEnabled),
        this.store.select(UserPreferenceUIPreferenceSelectors.isDashboardIntroPageVisited),
        this.store.select(UserPreferenceUIPreferenceSelectors.isAutomationIntroPageVisited),
        this.store.select(UserPreferenceUIPreferenceSelectors.isReportIntroPageVisited),
      ]).subscribe(
        ([
          hasDashboardPerm,
          hasWidgetsPerm,
          hasDashboardManagePerm,
          hasContentPerm,
          hasAutomationPerm,
          hasReportsPerm,
          hasReportManagePerm,
          isStandaloneApteligentEnabled,
          isDashboardIntroPageVisited,
          isAutomationIntroPageVisited,
          isReportIntroPageVisited,
        ]: boolean[]) => {
          this.showSearchField = isDashboardIntroPageVisited || isAutomationIntroPageVisited || isReportIntroPageVisited;
          if (!this.showSearchField) {
            return;
          }
          this.addCategories(
            hasDashboardPerm,
            hasWidgetsPerm,
            hasDashboardManagePerm,
            hasContentPerm,
            hasAutomationPerm,
            hasReportsPerm,
            hasReportManagePerm,
            isStandaloneApteligentEnabled,
            isDashboardIntroPageVisited,
            isAutomationIntroPageVisited,
            isReportIntroPageVisited,
          );
          this.initCategoryStates();
        },
      ),
    );
  }

  /**
   * ngOnDestroy
   * @memberof GlobalHeaderSearchComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * onCategoryChange
   * @param {GlobalSearchCategory} category
   * @memberof GlobalHeaderSearchComponent
   */
  public onCategoryChange(category: string) {
    this.activeCategory = category as GlobalSearchCategory;
  }

  /**
   * onSearch
   * @param {GlobalSearchChangeEvent} changeEvent
   * @memberof GlobalHeaderSearchComponent
   */
  public onSearch(changeEvent: GlobalSearchChangeEvent) {
    this.stateByCategory[changeEvent.searchCategory]?.onSearch(changeEvent.searchQuery);
  }

  /**
   * onSelectItem
   * @param {any} item
   * @memberof GlobalHeaderSearchComponent
   */
  public onSelectItem(item: any) {
    if (!item) {
      return;
    }
    this.stateByCategory[this.activeCategory]?.onSelectItem(item);
  }

  /**
   * addCategories
   * @param {boolean} hasDashboardPerm
   * @param {boolean} hasWidgetsPerm
   * @param {boolean} hasDashboardManagePerm
   * @param {boolean} hasContentPerm
   * @param {boolean} hasAutomationPerm
   * @param {boolean} hasReportsPerm
   * @param {boolean} hasReportManagePerm
   * @param {boolean} isStandaloneApteligentOrg
   * @param {boolean} isDashboardIntroPageVisited
   * @param {boolean} isAutomationIntroPageVisited
   * @param {boolean} isReportIntroPageVisited
   * @memberOf GlobalHeaderSearchComponent
   */
  private addCategories(
    hasDashboardPerm: boolean,
    hasWidgetsPerm: boolean,
    hasDashboardManagePerm: boolean,
    hasContentPerm: boolean,
    hasAutomationPerm: boolean,
    hasReportsPerm: boolean,
    hasReportManagePerm: boolean,
    isStandaloneApteligentOrg: boolean,
    isDashboardIntroPageVisited: boolean,
    isAutomationIntroPageVisited: boolean,
    isReportIntroPageVisited: boolean,
  ) {
    this.visibleCategories.clear();

    // Automation
    if (hasAutomationPerm && isAutomationIntroPageVisited) {
      this.visibleCategories.add(GlobalSearchCategory.AUTOMATION);
    }

    // Reports
    if ((hasReportsPerm || hasReportManagePerm) && isReportIntroPageVisited) {
      this.visibleCategories.add(GlobalSearchCategory.REPORT);
    }

    if ((hasDashboardPerm || hasWidgetsPerm) && isDashboardIntroPageVisited) {
      // Dashboard & Widget
      this.visibleCategories.add(GlobalSearchCategory.DASHBOARD);
      this.visibleCategories.add(GlobalSearchCategory.WIDGET);

      // Apps
      if (hasContentPerm && this.featureControls[FeatureControl.DASHBOARD_APP_ADOPTION_ENABLED]) {
        const appSearchCategory = isStandaloneApteligentOrg ? GlobalSearchCategory.APP_APTELIGENT : GlobalSearchCategory.APP;
        this.visibleCategories.add(appSearchCategory);
      }

      // OS
      if (this.featureControls[FeatureControl.DASHBOARD_ENROLLMENT_ENABLED]) {
        this.visibleCategories.add(GlobalSearchCategory.OS);
      }

      // DEVICES
      if (
        this.featureControls[FeatureControl.EMPLOYEE_EXPERIENCE_DEVICE_DETAILS_ENABLED] ||
        this.featureControls[FeatureControl.APTELIGENT_DEVICE_DETAILS_ENABLED]
      ) {
        this.visibleCategories.add(GlobalSearchCategory.DEVICE);
      }

      // Users
      if (this.featureControls[FeatureControl.USER_PROFILE_ENABLED]) {
        this.visibleCategories.add(GlobalSearchCategory.USER);
      }
    } else if (hasDashboardManagePerm && isDashboardIntroPageVisited) {
      this.visibleCategories.add(GlobalSearchCategory.DASHBOARD);
    }
  }

  /**
   * initCategoryStates
   * @private
   * @memberOf GlobalHeaderSearchComponent
   */
  private initCategoryStates() {
    this.visibleCategories.forEach((category: GlobalSearchCategory) => {
      this.stateByCategory[category]?.onInit?.();
    });
  }
}
