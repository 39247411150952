/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { FuzzySubgroupConfig } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GlobalSearchConfig } from '@dpa-components/global-header-search/global-search.config';
import { GlobalSearchActions, GlobalSearchSelectors, UserProfileActions } from '@dpa-shared-merlot';
import { DashboardSelectors } from '@ws1c/intelligence-core';
import { DeviceUser, GlobalSearchCategoryState } from '@ws1c/intelligence-models';

/**
 * UserSearchCategoryState
 * @export
 * @class UserSearchCategoryState
 * @implements {GlobalSearchCategoryState<DeviceUser>}
 */
@Injectable()
export class UserSearchCategoryState implements GlobalSearchCategoryState<DeviceUser> {
  public subgroupConfig$: Observable<FuzzySubgroupConfig>;
  public isLoading$: Observable<boolean>;
  public items$: Observable<DeviceUser[]>;
  public minQueryLength: number = GlobalSearchConfig.minSearchLength;

  /**
   * constructor
   * @param {Store} store
   * @memberof UserSearchCategoryState
   */
  constructor(private store: Store) {
    this.items$ = this.store.select(GlobalSearchSelectors.getUsersData);
    this.subgroupConfig$ = this.store.select(DashboardSelectors.getDashboardSubgroupConfig);
    this.isLoading$ = this.store.select(GlobalSearchSelectors.isGlobalSearchLoading);
  }

  /**
   * onSearch
   * @param {string} query
   * @memberof UserSearchCategoryState
   */
  public onSearch(query: string) {
    query = query.trim();
    if (query.length < GlobalSearchConfig.minSearchLength) {
      return;
    }
    this.store.dispatch(GlobalSearchActions.searchUsers({ query }));
  }

  /**
   * onSelectItem
   * @param {DeviceUser} user
   * @memberof UserSearchCategoryState
   */
  public onSelectItem(user: DeviceUser) {
    this.store.dispatch(UserProfileActions.goToUserProfilePage({ user }));
  }

  /**
   * keyBy
   * @param {DeviceUser} user
   * @returns {string}
   * @memberof UserSearchCategoryState
   */
  public keyBy(user: DeviceUser): string {
    return user.id ? `${user.userName} - ${user.id}` : user.userName;
  }
}
