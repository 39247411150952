/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';

import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { USER_COMPONENTS } from './index';

/**
 * UserModule contains user related components
 * @class UserModule
 */
@NgModule({
  imports: [IntelligenceCommonModule],
  declarations: [...USER_COMPONENTS],
  exports: [...USER_COMPONENTS],
})
export class UserModule {}
