<dpa-modal
  [modalClosable]="false"
  [modalOpen]="true"
>
  <div class="modal-body">
    <div class="reload-img"></div>
    <div class="modal-title mb-4">
      {{ 'COMMON_MESSAGES.SITE_UPDATE_COMPLETE' | translate }}
    </div>
    <div class="modal-desc mb-3">
      {{ 'COMMON_MESSAGES.SITE_UPDATE_COMPLETE_DESC' | translate }}
    </div>
    <div class="modal-reload-msg">
      {{ 'COMMON_MESSAGES.RELOAD_PAGE_MESSAGE' | translate }}
    </div>
  </div>
  <div class="modal-footer">
    <div class="button-group">
      <a
        [href]="overviewReleaseNotesUrl$ | async"
        target="_blank"
        rel="noopener"
        class="btn btn-link"
      >
        {{ 'COMMON_ACTIONS.VIEW_RELEASE_NOTES' | translate }}
        <cds-icon shape="pop-out"></cds-icon>
      </a>
      <button
        (click)="onReload()"
        class="btn btn-primary"
        type="button"
      >
        {{ 'COMMON_ACTIONS.RELOAD' | translate }}
      </button>
    </div>
  </div>
</dpa-modal>
