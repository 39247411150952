<dpa-modal
  [modalOpen]="isPreviewModalOpen$ | async"
  [modalSize]="ModalSize.XL"
  (onModalClose)="closeModal()"
>
  <dpa-modal-title>
    {{ previewModalTitle$ | async }}
  </dpa-modal-title>
  <div class="modal-body">
    <ng-template #modalContent></ng-template>
  </div>
  <div class="modal-footer">
    <button
      (click)="closeModal()"
      class="btn btn-outline"
    >
      {{ 'COMMON_ACTIONS.CANCEL' | translate }}
    </button>
    <button
      [routerLink]="buttonData?.secondary.route"
      class="btn btn-outline"
    >
      {{ buttonData?.secondary.text | translate }}
    </button>
    <button
      [routerLink]="buttonData?.primary.route"
      class="btn btn-primary"
    >
      {{ buttonData?.primary.text | translate }}
    </button>
  </div>
</dpa-modal>
