<dpa-common-menu
  [defaultMenuItemRoute]="defaultWorkspaceMenuItemRoute$ | async"
  [menuItems]="rootMenuItems$ | async"
></dpa-common-menu>
<ng-container *ngLet="showDashboardNavigationMenu$ | async as showDashboardNavigationMenu">
  <dpa-common-menu
    *ngIf="showDashboardNavigationMenu && (isUEMEnabled$ | async)"
    [menuItems]="uemMenuItems$ | async"
    [navGroup]="NavigationMenuGroup.WORKSPACE_UEM"
    [navGroupHeading]="'NAVIGATION.UEM' | translate"
    navGroupIconName="nodes"
  ></dpa-common-menu>
  <dpa-common-menu
    *ngIf="isHorizonEnabled$ | async"
    [menuItems]="horizonMenuItems$ | async"
    [navGroup]="NavigationMenuGroup.WORKSPACE_VIRTUAL_APPS_DESKTOP"
    [navGroupHeading]="'NAVIGATION.VIRTUAL_APPS_DESKTOPS' | translate"
    navGroupIconName="nodes"
  ></dpa-common-menu>
  <dpa-common-menu
    *ngIf="showDeemMenu$ | async"
    [menuItems]="deemMenuItems$ | async"
    [navGroup]="NavigationMenuGroup.WORKSPACE_EXPERIENCE_MANAGEMENT"
    [navGroupHeading]="'NAVIGATION.INTELLIGENCE_HEADER.DEEM' | translate"
    navGroupIconName="nodes"
  ></dpa-common-menu>
  <dpa-common-menu
    *ngIf="showDashboardNavigationMenu && (showWorkspaceSecurityMenu$ | async)"
    [menuItems]="workspaceSecurityMenuItems$ | async"
    [navGroup]="NavigationMenuGroup.WORKSPACE_WORKSPACE_SECURITY"
    [navGroupHeading]="'NAVIGATION.WORKSPACE_SECURITY' | translate"
    navGroupIconName="nodes"
  ></dpa-common-menu>
</ng-container>
<dpa-bookmarks-menu *ngIf="isUserBookmarksEnabled$ | async"></dpa-bookmarks-menu>
<dpa-standard-dashboard-uem-v2-toggle *ngIf="isUemV2DashboardToggleEnabledInFF$ | async"></dpa-standard-dashboard-uem-v2-toggle>
