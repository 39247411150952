/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { BreadCrumb, FuzzySubgroup, FuzzySubgroupConfig } from '@dpa/ui-common';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { GlobalSearchConfig } from '@dpa-components/global-header-search/global-search.config';
import { AppLoadsActions, AppLoadsSelectors, AppsDashboardActions } from '@dpa-shared-merlot';
import { DEEM_ROUTE_NAMES, DeemDashboardConfig, SubDashboardId, SubDashboardPlatform } from '@ws1c/deem-solution/const';
import { getRouterURLWithoutParameters, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { DashboardSelectors, NavigationActions } from '@ws1c/intelligence-core';
import {
  AirwatchAppSearchItem,
  App,
  AppPlatform,
  DeemAppDetailsPage,
  Entity,
  GlobalSearchCategoryState,
  Integration,
  UserDetailPageType,
} from '@ws1c/intelligence-models';

/**
 * AppSearchCategoryState
 * @export
 * @class AppSearchCategoryState
 * @implements {GlobalSearchCategoryState<AirwatchAppSearchItem>}
 */
@Injectable()
export class AppSearchCategoryState implements GlobalSearchCategoryState<AirwatchAppSearchItem> {
  public subgroupConfig$: Observable<FuzzySubgroupConfig>;
  public isLoading$: Observable<boolean>;
  public items$: Observable<AirwatchAppSearchItem[]>;
  public minQueryLength: number = GlobalSearchConfig.minSearchLength;
  public currentRouterURLWithoutParameters: string;

  private readonly INTEGRATION_TO_SUBCONFIG_TITLE = {
    [Integration.EMPLOYEE_EXPERIENCE]: 'INTEGRATIONS.EMPLOYEE_EXPERIENCE.DESKTOP_EMPLOYEE_EXPERIENCE',
    [Integration.APTELIGENT]: 'INTEGRATIONS.APTELIGENT.MOBILE_EMPLOYEE_EXPERIENCE',
  } as const;
  private readonly SubDashboardPlatformMap = {
    [AppPlatform.ANDROID]: SubDashboardPlatform.ANDROID,
    [AppPlatform.APPLE_IOS]: SubDashboardPlatform.IOS,
    [AppPlatform.APPLE_MACOS]: SubDashboardPlatform.MACOS,
    [AppPlatform.WINDOWS_DESKTOP]: SubDashboardPlatform.WINDOWS,
  } as const;

  /**
   * constructor
   * @param {I18NService} i18nService
   * @param {Store} store
   * @param {RouterExtensions} routerExt
   * @memberof AppSearchCategoryState
   */
  constructor(
    private i18nService: I18NService,
    private store: Store,
    private routerExt: RouterExtensions,
  ) {
    this.items$ = this.store.select(AppLoadsSelectors.getAppSearchList);
    this.isLoading$ = this.store.pipe(delay(0), select(AppLoadsSelectors.isLoadingAppList));
    this.subgroupConfig$ = this.store.select(DashboardSelectors.getAppsSubgroupConfig).pipe(
      // Special case for integrations in INTEGRATION_TO_SUBCONFIG_TITLE map in Global app search
      // The subgroup name will be different from the category name
      // This relies on the UI's translation service instead of the label from the meta API
      map((fuzzySubgroupConfig: FuzzySubgroupConfig) => {
        return {
          ...fuzzySubgroupConfig,
          subgroups: fuzzySubgroupConfig.subgroups.map((subGroup: FuzzySubgroup) => {
            const title = this.INTEGRATION_TO_SUBCONFIG_TITLE[subGroup.key]
              ? this.i18nService.translate(this.INTEGRATION_TO_SUBCONFIG_TITLE[subGroup.key])
              : subGroup.title;

            return {
              ...subGroup,
              title,
            };
          }),
        };
      }),
    );
    this.routerExt.url$?.subscribe((url: string) => {
      this.currentRouterURLWithoutParameters = getRouterURLWithoutParameters(url);
    });
  }

  /**
   * onSearch
   * @param {string} query
   * @memberof AppSearchCategoryState
   */
  public onSearch(query: string) {
    query = query.trim();
    if (query.length < GlobalSearchConfig.minSearchLength) {
      return;
    }
    this.store.dispatch(AppLoadsActions.searchApps({ query }));
  }

  /**
   * onSelectItem
   * @param {AirwatchAppSearchItem} app
   * @memberof AppSearchCategoryState
   */
  public onSelectItem(app: AirwatchAppSearchItem) {
    if (app.integration !== Integration.EMPLOYEE_EXPERIENCE) {
      const selectedApp = new App({
        id: app.packageId,
        apteligentAppId: app.apteligentAppId,
        name: app.name,
        platform: app.platform,
        appType: app.appType,
        integration: app.integration,
        isProductivityApp: app.isProductivityApp,
      });

      this.store.dispatch(AppLoadsActions.goToAppDetailPage({ app: selectedApp }));

      return;
    }

    const { name, platform } = app;
    const deemAppDetailsPage = new DeemAppDetailsPage({
      name,
      platform,
    });

    const subDashboardPlatform = this.SubDashboardPlatformMap[platform];
    const subDashboardId: string =
      subDashboardPlatform === SubDashboardPlatform.WINDOWS || SubDashboardPlatform.MACOS
        ? SubDashboardId.DESKTOP_APPS
        : SubDashboardId.MOBILE_APPS;
    const parentConfigKey: string = `${subDashboardId.replace('-', '_')}_${SubDashboardPlatform.ALL}`.toUpperCase();
    const breadCrumbs: BreadCrumb[] = [
      new BreadCrumb({
        pathUrl: DeemDashboardConfig.BreadCrumbUrlConfig[parentConfigKey]?.pathUrl,
        pathLabelKey: DeemDashboardConfig.BreadCrumbUrlConfig[parentConfigKey]?.pathLabelKey,
      }),
    ];
    deemAppDetailsPage.breadCrumbs = breadCrumbs;
    deemAppDetailsPage.entity =
      subDashboardId === SubDashboardId.DESKTOP_APPS ? Entity.EXPERIENCESCORE_DESKTOP_APP : Entity.EXPERIENCESCORE_MOBILE_APP;
    if (subDashboardPlatform) {
      const nextURL: string = DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL_APP_DETAIL(
        subDashboardId,
        SubDashboardPlatform.ALL,
        deemAppDetailsPage.name,
      );
      // if current url is not equal to next url, clear breadCrumbs;
      if (this.currentRouterURLWithoutParameters.indexOf(nextURL) === -1) {
        this.store.dispatch(
          NavigationActions.setBreadCrumbs({
            breadCrumbs: [],
          }),
        );
      }
      this.store.dispatch(
        AppsDashboardActions.goToDeemAppDetailsPage({
          deemAppDetailsPage,
          userDetailPageType: UserDetailPageType.DEEM_USER,
          subDashboardId,
        }),
      );
    }
  }

  /**
   * keyBy
   * @param {AirwatchAppSearchItem} app
   * @returns {string}
   * @memberof AppSearchCategoryState
   */
  public keyBy(app: AirwatchAppSearchItem): string {
    return app.packageIdLabel ? `${app.nameLabel} - ${app.packageIdLabel}` : app.nameLabel;
  }
}
