/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FullscreenService, UserIdleService } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { MerlotState, UserActions } from '@dpa-shared-merlot/store';
import { UserPreferenceSelectors } from '@ws1c/intelligence-core';
import { ROUTE_NAMES } from '@ws1c/intelligence-models';
// eslint-disable-next-line
import { environment } from '../../../../environments/environment';

/**
 * UserSessionComponent - session timeout popup
 * @export
 * @class UserSessionComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-user-session',
  templateUrl: 'user-session.component.html',
  styleUrls: ['user-session.component.scss'],
})
export class UserSessionComponent implements OnInit, OnDestroy {
  // user idle vars
  public showModal: boolean = false;
  public idleTimerMinute: number;
  public idleTimerSecond: number;
  public idleTimeout: number = environment.timeout;
  public sessionTimeoutAnimationConfig = {
    path: 'assets/svg-animations/session-timeout.json',
    loop: true,
  };
  public isTimedOut: boolean = false;
  public redirectUrl: string;
  public sub = new Subscription();

  /**
   * Creates an instance of UserSessionComponent.
   * @param {Store<MerlotState>} store
   * @param {UserIdleService} userIdle
   * @param {FullscreenService} fullscreenService
   * @param {NgZone} ngZone
   * @param {Router} router
   * @memberof UserSessionComponent
   */
  constructor(
    private store: Store<MerlotState>,
    private userIdle: UserIdleService,
    private fullscreenService: FullscreenService,
    private ngZone: NgZone,
    private router: Router,
  ) {
    this.onFullscreenChange = this.onFullscreenChange.bind(this);
  }

  /**
   * ngOnInit - startWatching
   * @memberof UserSessionComponent
   */
  public ngOnInit() {
    // Start watching for user inactivity.
    this.userIdleStartWatching();
    this.sub.add(
      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe((count: number) => {
        if (!count || this.router.url?.includes(ROUTE_NAMES.LOGIN)) {
          return;
        }
        this.ngZone.run(() => {
          this.showModal = true;
          this.countDown(count);
        });
      }),
    );

    this.sub.add(
      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {
        this.ngZone.run(() => {
          this.isTimedOut = true;
          this.silentLogout();
        });
      }),
    );

    this.sub.add(
      this.store.select(UserPreferenceSelectors.getOrgBaseUrl).subscribe((orgBaseUrl: string) => {
        this.redirectUrl = orgBaseUrl;
      }),
    );

    // Stop watching in Fullscreen mode
    this.fullscreenService.addEventListener('fullscreenchange', this.onFullscreenChange);
  }

  /**
   * ngOnDestroy
   *
   * @memberof UserSessionComponent
   */
  public ngOnDestroy() {
    this.userIdle.stopWatching();
    this.sub.unsubscribe();
    this.fullscreenService.removeEventListener('fullscreenchange', this.onFullscreenChange);
  }

  /**
   * idleLogout - excute this.onLogout()
   * @memberof UserSessionComponent
   */
  public idleLogout() {
    this.store.dispatch(UserActions.logout());
  }

  /**
   * startWatching - reset timer & start watching
   * @memberof UserSessionComponent
   */
  public startWatching() {
    this.showModal = false;
    this.restart();
    this.userIdleStartWatching();
  }

  /**
   * gotoLogin - redirect to wso login
   * @memberof UserSessionComponent
   */
  public gotoLogin() {
    window.location.href = this.redirectUrl;
  }

  /**
   * silentLogout - this.onLogout() without redirection
   * @memberof UserSessionComponent
   */
  public silentLogout() {
    this.store.dispatch(UserActions.silentLogout());
  }

  /**
   * restart - timer & session
   * @memberof UserSessionComponent
   */
  private restart() {
    this.userIdle.resetTimer();
    this.renewSession();
  }

  /**
   * renewSession
   * @memberof UserSessionComponent
   */
  private renewSession() {
    this.store.dispatch(UserActions.renewSession());
  }

  /**
   * countDown - for given timeout, start countdown into minutes & seconds
   * @param {number} ticker
   * @memberof UserSessionComponent
   */
  private countDown(ticker: number) {
    const tickerDiff = this.idleTimeout - ticker;
    this.idleTimerMinute = Math.max(Math.floor(tickerDiff / 60), 0);
    this.idleTimerSecond = Math.max(tickerDiff % 60, 0);

    if (this.idleTimerMinute === 0 && this.idleTimerSecond === 0) {
      this.userIdle.stopWatching();
    }
  }

  /**
   * onFullscreenChange
   *
   * @memberof UserSessionComponent
   */
  private onFullscreenChange() {
    if (this.fullscreenService.fullscreenElement) {
      this.userIdle.stopWatching();
    } else {
      this.userIdleStartWatching();
    }
  }

  /**
   * userIdleStartWatching
   *
   * @memberof UserSessionComponent
   */
  private userIdleStartWatching() {
    this.ngZone.runOutsideAngular(() => {
      this.userIdle.startWatching();
    });
  }
}
