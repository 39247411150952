/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { NavigationMenuSelectors, SolutionSetupSelectors, UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core';
import { MenuItem, NavigationMenuGroup, NavigationMenuItem } from '@ws1c/intelligence-models';

/**
 * WorkspaceMenuComponent
 * @export
 * @class WorkspaceMenuComponent
 */
@Component({
  selector: 'dpa-workspace-menu',
  templateUrl: 'workspace-menu.component.html',
  styleUrls: ['workspace-menu.component.scss'],
})
export class WorkspaceMenuComponent {
  public workspaceSecurityMenuItems$: Observable<MenuItem[]>;
  public uemMenuItems$: Observable<MenuItem[]>;
  public horizonMenuItems$: Observable<MenuItem[]>;
  public deemMenuItems$: Observable<MenuItem[]>;
  public rootMenuItems$: Observable<MenuItem[]>;
  public defaultWorkspaceMenuItemRoute$: Observable<string>;
  public isHorizonEnabled$: Observable<boolean>;
  public isUEMEnabled$: Observable<boolean>;
  public showDashboardNavigationMenu$: Observable<boolean>;
  public showWorkspaceSecurityMenu$: Observable<boolean>;
  public showDeemMenu$: Observable<boolean>;
  public isUserBookmarksEnabled$: Observable<boolean>;
  public isUemV2DashboardToggleEnabledInFF$: Observable<boolean>;

  public readonly NavigationMenuItem = NavigationMenuItem;
  public readonly NavigationMenuGroup = NavigationMenuGroup;

  /**
   * Creates an instance of WorkspaceMenuComponent.
   * @param {Store<MerlotState>} store - Merlot Store observable instance
   * @memberof WorkspaceMenuComponent
   */
  constructor(private store: Store<MerlotState>) {
    this.defaultWorkspaceMenuItemRoute$ = this.store.select(NavigationMenuSelectors.defaultWorkspaceMenuItemRoute);
    this.showDashboardNavigationMenu$ = this.store.select(NavigationMenuSelectors.showDashboardNavigationMenu);
    this.isUEMEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isUEMEnabled);
    this.isHorizonEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isHorizonEnabled);
    this.showDeemMenu$ = this.store.select(SolutionSetupSelectors.isAnyDeemSolutionsReady);
    this.showWorkspaceSecurityMenu$ = this.store.select(NavigationMenuSelectors.showWorkspaceSecurityMenu);

    this.rootMenuItems$ = this.store.select(NavigationMenuSelectors.getVisibleMenuItems(NavigationMenuItem.WORKSPACE));
    this.uemMenuItems$ = this.store.select(NavigationMenuSelectors.getVisibleMenuItems(NavigationMenuItem.WORKSPACE_UEM));
    this.horizonMenuItems$ = this.store.select(NavigationMenuSelectors.getVisibleMenuItems(NavigationMenuItem.WORKSPACE_HORIZON));
    this.deemMenuItems$ = this.store.select(
      NavigationMenuSelectors.getVisibleMenuItems(NavigationMenuItem.WORKSPACE_EXPERIENCE_MANAGEMENT),
    );
    this.workspaceSecurityMenuItems$ = this.store.select(NavigationMenuSelectors.getWorkspaceSecurityMenuItems);
    this.isUserBookmarksEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isUserBookmarksEnabled);
    this.isUemV2DashboardToggleEnabledInFF$ = this.store.select(UserPreferenceFeatureControlsSelectors.isUemV2DashboardToggleEnabled);
  }
}
