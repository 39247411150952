<clr-main-container
  [ngClass]="{ 'apteligent-mode': isStandaloneApteligentEnabled$ | async, 'ws-one-cloud-mode': isWorkspaceOneCloudOrg }"
  class="app-view"
>
  <dpa-tooltip-view></dpa-tooltip-view>
  <dpa-alert-banner [target]="ALERT_BANNER_TARGET.APP"></dpa-alert-banner>
  <dpa-trial-banner *ngIf="hideTrialBanner === false"></dpa-trial-banner>
  <dpa-banner-notification *ngIf="(isNotificationEnabled$ | async) && (hasUserScopes$ | async)"></dpa-banner-notification>
  <dpa-cookie-policy-banner *ngIf="showCookiePolicyBanner$ | async"></dpa-cookie-policy-banner>
  <clr-header>
    <dpa-header [brandingTemplate]="isWorkspaceOneCloudOrg ? wsOneCloudBranding : wsOneIntelBranding"></dpa-header>
  </clr-header>
  <div
    #contentContainer
    class="full-page-container"
  >
    <dpa-navigation-menu *ngIf="(isNavigationRestricted$ | async) === false"></dpa-navigation-menu>
    <div class="content-container">
      <nav
        *ngIf="showSecondaryNavigation$ | async"
        class="sidenav"
      >
        <dpa-secondary-navigation-menu></dpa-secondary-navigation-menu>
      </nav>
      <main
        [class.banner-visible]="banner.isVisible"
        class="content-area"
        aria-label="main"
        role="main"
      >
        <dpa-alert-banner
          #banner
          [target]="ALERT_BANNER_TARGET.PAGE"
        ></dpa-alert-banner>
        <dpa-brownfield-notification-modal *ngIf="isBrownfieldNotificationModalShown$ | async"></dpa-brownfield-notification-modal>
        <dpa-request-cloud-account-modal *ngIf="isRequestCloudAccountModalOpen$ | async"></dpa-request-cloud-account-modal>
        <dpa-intro-modal *ngIf="hasUserScopes$ | async"></dpa-intro-modal>
        <router-outlet></router-outlet>
      </main>
      <dpa-navigation-preview-modal></dpa-navigation-preview-modal>
      <dpa-active-widget-copy-modal></dpa-active-widget-copy-modal>
      <!-- keep this at the end, this modal can appear on top of other modals -->
      <dpa-navigation-confirm-modal></dpa-navigation-confirm-modal>
    </div>
  </div>
</clr-main-container>

<div class="main-container maintenance-view">
  <header class="header">
    <div class="branding branding-wrapper">
      <ng-template
        [ngTemplateOutlet]="isWorkspaceOneCloudOrg ? wsOneCloudBranding : wsOneIntelBranding"
        [ngTemplateOutletContext]="{ isRemediationMode: isRemediationMode$ | async }"
      ></ng-template>
    </div>
  </header>
  <div class="content-container">
    <main
      class="content-area"
      aria-label="main"
      role="main"
    >
      <dpa-maintenance></dpa-maintenance>
    </main>
  </div>
</div>

<ng-template
  #wsOneIntelBranding
  let-isRemediationMode
>
  <div
    [class.is-remediation-mode]="isRemediationMode"
    class="ws-intel-logo-container"
  >
    <div
      [class.is-remediation-mode]="isRemediationMode"
      class="ws-intel-logo"
    ></div>
  </div>
  <div class="ws-intel-text-container clr-hidden-sm-down">
    <div
      [class.is-remediation-mode]="isRemediationMode"
      class="ws-intel-text"
    ></div>
  </div>
</ng-template>

<ng-template
  #wsOneCloudBranding
  let-isRemediationMode
>
  <div
    [class.is-remediation-mode]="isRemediationMode"
    class="workspace-one-logo-container"
  >
    <div class="workspace-one-logo"></div>
  </div>
</ng-template>

<dpa-user-session></dpa-user-session>
