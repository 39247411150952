/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ComponentRef, createNgModule, Injectable, Injector, NgModuleRef, Type, ViewContainerRef } from '@angular/core';
import { GenericObject } from '@dpa/ui-common';
import { connectable, EMPTY, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { LazyComponentType } from '@ws1c/intelligence-models';
import { LazyFeatureStoreType } from '@ws1c/intelligence-models/lazy-load/lazy-feature-store-type.enum';
import { DYNAMIC_IMPORT_MAP } from './dynamic-import-map.const';

/**
 * ComponentModule
 * @exports
 * @interface ComponentModule
 */
export interface ComponentModule<T> {
  componentType: Type<T>;
  moduleRef: NgModuleRef<any>;
}

/**
 * LazyLoadService
 * @export
 * @class LazyLoadService
 */
@Injectable({
  providedIn: 'root',
})
export class LazyLoadService {
  public componentModuleMap: Map<LazyComponentType | LazyFeatureStoreType, ComponentModule<any>> = new Map();

  /**
   * Creates an instance of LazyLoadService.
   * @param {Injector} injector
   * @memberof LazyLoadService
   */
  constructor(private injector: Injector) {}

  /**
   * buildComponent
   * @description Lazy loads component into provided ViewContainerRef and caches. Returns hot observable of component.
   * @param {LazyComponentType} componentType
   * @param {ViewContainerRef} viewContainerRef
   * @param {GenericObject} props
   * @returns {Observable<ComponentRef<any>>}
   * @memberof LazyLoadService
   */
  public buildComponent(
    componentType: LazyComponentType,
    viewContainerRef: ViewContainerRef,
    props?: GenericObject,
  ): Observable<ComponentRef<any>> {
    if (componentType === undefined || viewContainerRef === undefined) {
      return EMPTY;
    }
    viewContainerRef.clear();
    if (this.componentModuleMap.has(componentType)) {
      return of(this.createComponent(this.componentModuleMap.get(componentType), viewContainerRef, props));
    }

    const componentRef$ = connectable(
      this.compileLazyModule(componentType)?.pipe(
        map((componentModule: ComponentModule<any>) => this.createComponent(componentModule, viewContainerRef, props)),
      ),
      { connector: () => new Subject() },
    );
    componentRef$.subscribe();
    componentRef$.connect();
    return componentRef$;
  }

  /**
   * loadFeatureStore
   * @description Lazy loads feature store. Returns observable of moduleRef.
   * @param {LazyFeatureStoreType} featureStoreType
   * @returns {Observable<NgModule<>any>}
   * @memberof LazyLoadService
   */
  public loadFeatureStore(featureStoreType: LazyFeatureStoreType): Observable<NgModuleRef<any>> {
    if (featureStoreType === undefined) {
      return EMPTY;
    }

    if (this.componentModuleMap.has(featureStoreType)) {
      return of(this.componentModuleMap.get(featureStoreType)?.moduleRef);
    }

    return this.compileLazyModule(featureStoreType).pipe(map((componentModule: ComponentModule<any>) => componentModule.moduleRef));
  }

  /**
   * compileLazyModule
   * @description Asynchronously compile and instantiate component module, resolve component type if available.
   * @param {LazyComponentType} lazyType
   * @returns {Observable<ComponentModule<any>>}
   * @memberof LazyLoadService
   */
  public compileLazyModule(lazyType: LazyComponentType | LazyFeatureStoreType): Observable<ComponentModule<any>> {
    return DYNAMIC_IMPORT_MAP[lazyType].pipe(
      map((module: any) => {
        const moduleRef = createNgModule(module, this.injector);
        return {
          moduleRef,
          componentType: (moduleRef?.instance as any)?.resolveComponent?.(),
        };
      }),
    );
  }

  /**
   * createComponent
   * @description Create component from component factory and module injector and set up prop bindings.
   * @param {ComponentModule<any>} componentModule
   * @param {ViewContainerRef} viewContainerRef
   * @param {GenericObject} props
   * @returns {ComponentRef<any>}
   * @memberof LazyLoadService
   */
  public createComponent(
    componentModule: ComponentModule<any>,
    viewContainerRef: ViewContainerRef,
    props?: GenericObject,
  ): ComponentRef<any> {
    const componentRef = viewContainerRef?.createComponent(componentModule.componentType, {
      injector: componentModule?.moduleRef?.injector,
    });
    const componentInstance = componentRef?.instance;
    if (componentInstance && props) {
      Object.assign(componentInstance, props);
      componentRef.changeDetectorRef.detectChanges();
    }
    return componentRef;
  }
}
