/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { NavigationMenuSelectors } from '@ws1c/intelligence-core';
import { MenuItem } from '@ws1c/intelligence-models';

/**
 * Integrations Menu Component
 *
 * @export
 * @class IntegrationsMenuComponent
 */
@Component({
  selector: 'dpa-integrations-menu',
  templateUrl: 'integrations-menu.component.html',
  styleUrls: ['integrations-menu.component.scss'],
})
export class IntegrationsMenuComponent {
  public availableIntegrationsMenuItems$: Observable<MenuItem[]>;
  public defaultIntegrationsMenuItemRoute$: Observable<string>;

  /**
   * Creates an instance of IntegrationsMenuComponent
   * @param {Store<MerlotState>} store - Merlot Store observable instance
   * @memberof IntegrationsMenuComponent
   */
  constructor(private store: Store<MerlotState>) {
    this.availableIntegrationsMenuItems$ = this.store.select(NavigationMenuSelectors.availableIntegrationsMenuItems);
    this.defaultIntegrationsMenuItemRoute$ = this.store.select(NavigationMenuSelectors.defaultIntegrationsMenuItemRoute);
  }
}
