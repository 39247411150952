/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { FuzzySubgroupConfig } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GlobalSearchActions, GlobalSearchSelectors } from '@dpa-shared-merlot';
import { ReportCommonActions, ReportCommonSelectors } from '@ws1c/intelligence-core';
import { CustomReport, GlobalSearchCategoryState } from '@ws1c/intelligence-models';

/**
 * ReportSearchCategoryState
 * @export
 * @class ReportSearchCategoryState
 * @implements {GlobalSearchCategoryState<CustomReport>}
 */
@Injectable()
export class ReportSearchCategoryState implements GlobalSearchCategoryState<CustomReport> {
  public subgroupConfig$: Observable<FuzzySubgroupConfig>;
  public isLoading$: Observable<boolean>;
  public items$: Observable<CustomReport[]>;

  /**
   * constructor
   * @param {Store} store
   * @memberof ReportSearchCategoryState
   */
  constructor(private store: Store) {
    this.items$ = this.store.select(GlobalSearchSelectors.getReportsData);
    this.subgroupConfig$ = this.store.select(ReportCommonSelectors.getReportSubgroupConfig);
    this.isLoading$ = this.store.select(GlobalSearchSelectors.isGlobalSearchLoading);
  }

  /**
   * onSearch
   * @param {string} query
   * @memberof ReportSearchCategoryState
   */
  public onSearch(query: string) {
    this.store.dispatch(GlobalSearchActions.searchReports({ query }));
  }

  /**
   * onSelectItem
   * @param {CustomReport} report
   * @memberof ReportSearchCategoryState
   */
  public onSelectItem(report: CustomReport) {
    this.store.dispatch(
      ReportCommonActions.goToReportDetailsPage({
        reportId: report.id,
      }),
    );
  }

  /**
   * keyBy
   * @param {CustomReport} report
   * @returns {string}
   * @memberof ReportSearchCategoryState
   */
  public keyBy(report: CustomReport): string {
    return report.name;
  }
}
