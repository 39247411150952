/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * Maintenance View
 * @export
 * @class MaintenanceComponent
 */
@Component({
  selector: 'dpa-maintenance',
  templateUrl: 'maintenance.component.html',
  styleUrls: ['maintenance.component.scss'],
})
export class MaintenanceComponent {}
