/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HttpClientXsrfModule, HttpHandler } from '@angular/common/http';
// import locale
import localeCZ from '@angular/common/locales/cs';
import localeDK from '@angular/common/locales/da';
import localeDE from '@angular/common/locales/de';
import localeES from '@angular/common/locales/es';
import localeFR from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import localeJP from '@angular/common/locales/ja';
import localeKR from '@angular/common/locales/ko';
import localeNL from '@angular/common/locales/nl';
import localePL from '@angular/common/locales/pl';
import localeBR from '@angular/common/locales/pt';
import localeRU from '@angular/common/locales/ru';
import localeSE from '@angular/common/locales/sv';
import localeTR from '@angular/common/locales/tr';
// eslint-disable-next-line import/no-duplicates
import localeTW from '@angular/common/locales/zh';
// eslint-disable-next-line import/no-duplicates
import localeCN from '@angular/common/locales/zh';
import { APP_INITIALIZER, ApplicationRef, ErrorHandler, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ClrCommonStringsService } from '@clr/angular';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { getSupportedLocale, UserIdleModule } from '@dpa/ui-common';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { BehaviorSubject } from 'rxjs';

// config
import { CookiePolicyBannerModule } from '@dpa-components/cookie-policy-banner/cookie-policy-banner.module';
import { HeaderModule } from '@dpa-components/header/header.module';
import { NavigationMenuModule } from '@dpa-components/navigation-menu/navigation-menu.module';
import { registerDpaIcons } from '@dpa-icons';
import { httpInterceptorProviders } from '@dpa-shared-core/interceptors';
import { appEffectsRun } from '@dpa-shared-merlot/effects';
import { MerlotModule } from '@dpa-shared-merlot/merlot.module';
import { appReducer } from '@dpa-shared-merlot/store';
import { AppConfig, CookiesService, HttpCache, HttpService, IntelligenceCommonModule, RouterExtensions } from '@ws1c/intelligence-common';
import { I18nResourcesLoader, IntelligenceCoreComponentsModule, UserPreferenceService } from '@ws1c/intelligence-core';
import { BannerNotificationModule } from '@ws1c/notification/components/banner-notification/banner-notification.module';
// eslint-disable-next-line
import { environment } from '../environments/environment';
import { APP_COMPONENTS, AppComponent } from './components';
import { routes } from './components/app.routes';
import { UserModule } from './components/user/user.module';
import { AppErrorHandler } from './shared/core/error-handlers/app-error-handler';

// import moment locale
import 'moment/locale/cs.js';
import 'moment/locale/da.js';
import 'moment/locale/de.js';
import 'moment/locale/en-gb.js';
import 'moment/locale/es.js';
import 'moment/locale/fr.js';
import 'moment/locale/it.js';
import 'moment/locale/ja.js';
import 'moment/locale/ko.js';
import 'moment/locale/nl.js';
import 'moment/locale/pl.js';
import 'moment/locale/pt.js';
import 'moment/locale/pt-br.js';
import 'moment/locale/ru.js';
import 'moment/locale/sv.js';
import 'moment/locale/tr.js';
import 'moment/locale/zh-cn.js';
import 'moment/locale/zh-tw.js';

declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Array<Provider | EnvironmentProviders>;
  }
}

const routerModule = RouterModule.forRoot(routes, { useHash: true });

declare var window; // eslint-disable-line no-var

// register locale
[
  localeCZ,
  localeDK,
  localeDE,
  localeES,
  localeFR,
  localeIT,
  localeJP,
  localeKR,
  localeNL,
  localePL,
  localeBR,
  localeRU,
  localeSE,
  localeTR,
  localeCN,
  localeTW,
].forEach(registerLocaleData);

// register custom-clarity icons
registerDpaIcons();

// For AoT compilation to work:
/**
 * AOT getter for window object
 *
 * @export
 * @returns {any}
 */
export function win() {
  return window;
}

// for AoT compilation
/**
 * translateFactory
 * Load resources from local json files
 *
 * @param {HttpClient} http
 * @param {ClrCommonStringsService} clrCommonStringsService
 * @export
 * @returns {I18nResourcesLoader}
 */
export function translateFactory(http: HttpClient, clrCommonStringsService: ClrCommonStringsService) {
  return new I18nResourcesLoader(http, clrCommonStringsService);
}

/**
 * AppInitializer Factory
 * Handle Authentication Check
 * Init moment with locale
 * @export
 * @param {UserPreferenceService} userPreferenceService
 * @returns {() => Promise<any>}
 */
export function appInitializerFactory(userPreferenceService: UserPreferenceService): () => Promise<any> {
  return (): Promise<any> => {
    return userPreferenceService.init();
  };
}

/**
 * languageInitializerFactory
 * Loads the required language bundle (JSON file)
 * @export
 * @param {UserPreferenceService} userPreferenceService
 * @returns {Promise<object>}
 */
export function languageInitializerFactory(userPreferenceService: UserPreferenceService): () => Promise<any> {
  return (): Promise<any> => {
    return userPreferenceService.setLanguage();
  };
}

/**
 * httpService Factory
 * Inject CookiesService to allow accessing AUTH_TOKEN/API_URL from cookies
 *
 * @export
 * @param {HttpHandler} httpHandler
 * @param {CookiesService} cookiesService
 * @returns {HttpService}
 */
export function httpServiceFactory(httpHandler: HttpHandler, cookiesService: CookiesService) {
  return new HttpService(httpHandler, cookiesService);
}

/**
 * Main Application Module
 * @export
 * @class AppModule
 */
@NgModule({
  imports: [
    // angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    HttpCacheInterceptorModule.forRoot(),
    routerModule,

    // 3rd party lib modules
    TranslateModule.forRoot({
      extend: true,
      loader: {
        provide: TranslateLoader,
        useFactory: translateFactory,
        deps: [HttpClient, ClrCommonStringsService],
      },
    }),
    UserIdleModule.forRoot({
      idle: environment.idle,
      timeout: environment.timeout,
      ping: 0,
    }),
    EffectsModule.forRoot(appEffectsRun),
    StoreModule.forRoot(appReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
    environment.production ? [] : StoreDevtoolsModule.instrument({ name: 'Intelligence' }),

    // application modules
    IntelligenceCommonModule,
    IntelligenceCoreComponentsModule,
    HeaderModule,
    NavigationMenuModule,
    UserModule,
    CookiePolicyBannerModule,
    BannerNotificationModule,
    MerlotModule,
  ],
  declarations: [APP_COMPONENTS],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: (router: RouterExtensions) => {
        return new AppErrorHandler(router);
      },
      deps: [RouterExtensions],
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: HttpService,
      useFactory: httpServiceFactory,
      deps: [HttpHandler, CookiesService, Store],
    },
    httpInterceptorProviders,
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [UserPreferenceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: languageInitializerFactory,
      deps: [UserPreferenceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService, HttpCache],
      multi: true,
    },
    {
      // provide using string token, libraries when used as webcomponents may not be able to inject otherwise
      provide: AppConfig.ACTIVE_LOCALE,
      useValue: new BehaviorSubject(undefined),
    },
    {
      // Set the locale for timepicker
      // value for the OWL_DATE_TIME_LOCALE token should be lang abbr(eg. 'fr').
      // (https://danielykpan.github.io/date-time-picker/#locale-formats)
      provide: OWL_DATE_TIME_LOCALE,
      useValue: getSupportedLocale().split('_')[0],
    },
    {
      provide: LOCALE_ID,
      useFactory: (translateService: TranslateService) => {
        return translateService.currentLang;
      },
      deps: [TranslateService],
    },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  /**
   * constructor
   * @param {ApplicationRef} appRef
   * @param {ErrorHandler} errorHandler
   * @memberOf AppModule
   */
  constructor(
    private appRef: ApplicationRef,
    private errorHandler: ErrorHandler,
  ) {
    if (isDevMode()) {
      const CD_THRESHOLD_MS = 500;
      const origFn = this.appRef.tick.bind(this.appRef);

      // override appRef.tick function
      this.appRef.tick = () => {
        const start = performance.now();
        origFn();
        const end = performance.now();
        const diff = end - start;
        if (diff > CD_THRESHOLD_MS) {
          this.errorHandler.handleError(`Performance: Change Detection cycle took ${diff}ms. It should be less than ${CD_THRESHOLD_MS}ms`);
        }
      };
    }
  }
}
