/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalSize } from '@dpa/ui-common/model';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { AppConfig, CookiesService, Endpoint } from '@ws1c/intelligence-common';
import {
  UserAdminAccountActions,
  UserAdminAccountSelector,
  UserPreferenceActions,
  UserPreferenceAssetsSelectors,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceSelectors,
} from '@ws1c/intelligence-core';
import { AlertBannerTarget, RequestStatusType } from '@ws1c/intelligence-models';

/**
 * BrownfieldNotificationModalComponent
 * @export
 * @class BrownfieldNotificationModalComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-brownfield-notification-modal',
  templateUrl: 'brownfield-notification-modal.component.html',
  styleUrls: ['brownfield-notification-modal.component.scss'],
})
export class BrownfieldNotificationModalComponent implements OnInit, OnDestroy {
  public isBrownfieldNotificationModalOpen$: Observable<boolean>;
  public remainingBrownfieldTransitionDays$: Observable<number>;
  public isBrownfieldLimitedAvailabilityEnabled$: Observable<boolean>;

  public ALERT_BANNER_TARGET = AlertBannerTarget;
  public cspAccountRequestEmail: string;
  public cspAccountRequestStatus: RequestStatusType;
  public isEditMode: boolean = false;
  public loginWithCloudUrl: string;
  public subscription: Subscription = new Subscription();
  public workspaceOneIntelligenceDocUrl: string;
  public isUserCspAccountMerged: boolean = false;

  public readonly REQUEST_STATUS_TYPE: typeof RequestStatusType = RequestStatusType;
  public readonly ModalSize = ModalSize;

  /**
   * Creates instance of BrownfieldNotificationModalComponent
   * @param {Store<MerlotState>} store - Merlot Store observable instance
   * @param {CookiesService} cookiesService
   * @memberof BrownfieldNotificationModalComponent
   */
  constructor(
    private store: Store<MerlotState>,
    private cookiesService: CookiesService,
  ) {
    this.isBrownfieldNotificationModalOpen$ = this.store.select(UserPreferenceSelectors.isBrownfieldNotificationModalOpen);
    this.remainingBrownfieldTransitionDays$ = this.store.select(UserPreferenceSelectors.getRemainingBrownfieldTransitionDays);
    this.isBrownfieldLimitedAvailabilityEnabled$ = this.store.select(
      UserPreferenceFeatureControlsSelectors.isBrownfieldLimitedAvailibityEnabled,
    );
    this.loginWithCloudUrl = (this.cookiesService.getCookie(AppConfig.API_URL) || '') + Endpoint.IAM_ACCOUNT_MERGE;
  }

  /**
   * ngOnInit
   * Dispatches action to get csp account request
   * @memberof BrownfieldNotificationModalComponent
   */
  public ngOnInit() {
    this.store.dispatch(UserAdminAccountActions.getCspAccountRequest());
    this.subscription.add(
      this.store.select(UserAdminAccountSelector.getCspAccountRequestStatus).subscribe((cspAccountRequestStatus: RequestStatusType) => {
        this.cspAccountRequestStatus = cspAccountRequestStatus;
        if (cspAccountRequestStatus === this.REQUEST_STATUS_TYPE.REQUESTED) {
          this.setEditEmailMode(false);
        }
      }),
    );
    this.subscription.add(
      this.store.select(UserAdminAccountSelector.getCspAccountRequestEmail).subscribe((cspAccountRequestEmail: string) => {
        this.cspAccountRequestEmail = cspAccountRequestEmail;
      }),
    );
    this.subscription.add(
      this.store
        .select(UserPreferenceAssetsSelectors.getWorkspaceOneIntelligenceDocUrl)
        .pipe(take(1))
        .subscribe((workspaceOneIntelligenceDocUrl: string) => {
          this.workspaceOneIntelligenceDocUrl = workspaceOneIntelligenceDocUrl;
        }),
    );
    this.subscription.add(
      this.store.select(UserPreferenceSelectors.getUserAccountCspMergeStatus).subscribe((isMerged: boolean) => {
        this.isUserCspAccountMerged = isMerged;
      }),
    );
  }

  /**
   * Unsubscribes from the subscription
   * @memberof BrownfieldNotificationModalComponent
   */
  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Dispatches action to close brownfield notification modal
   * @memberof BrownfieldNotificationModalComponent
   */
  public closeBrownfieldNotificationModal() {
    this.store.dispatch(UserPreferenceActions.setBrownfieldNotificationModalOpenState({ isOpen: false }));
  }

  /**
   * Set edit email mode
   * @param {boolean} isEditMode
   * @memberof BrownfieldNotificationModalComponent
   */
  public setEditEmailMode(isEditMode: boolean) {
    this.isEditMode = isEditMode;
  }

  /**
   * Returns true if login with cloud option need to be displayed
   * @returns {boolean}
   * @memberof BrownfieldNotificationModalComponent
   */
  public showLoginWithCloudOption(): boolean {
    return this.isUserCspAccountMerged || this.cspAccountRequestStatus === this.REQUEST_STATUS_TYPE.GRANTED;
  }

  /**
   * Returns true if request cloud account option need to be displayed
   * @returns {boolean}
   * @memberof BrownfieldNotificationModalComponent
   */
  public showRequestCloudAccountOption(): boolean {
    return !this.isUserCspAccountMerged && (this.cspAccountRequestStatus === this.REQUEST_STATUS_TYPE.NONE || this.isEditMode);
  }

  /**
   * Returns true if csp account request has already been submitted
   * @returns {boolean}
   * @memberof BrownfieldNotificationModalComponent
   */
  public isCspAccountRequestAlreadySubmitted(): boolean {
    return this.cspAccountRequestStatus === this.REQUEST_STATUS_TYPE.REQUESTED && !this.isEditMode;
  }
}
