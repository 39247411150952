/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { ModalSize } from '@dpa/ui-common/model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store';
import { NavigationActions, NavigationSelectors } from '@ws1c/intelligence-core';
import { BlockerStatus } from '@ws1c/intelligence-models';

/**
 * NavigationConfirmModalComponent
 * @export
 * @class NavigationConfirmModalComponent
 */
@Component({
  selector: 'dpa-navigation-confirm-modal',
  templateUrl: 'navigation-confirm-modal.component.html',
  styleUrls: ['navigation-confirm-modal.component.scss'],
})
export class NavigationConfirmModalComponent {
  public isConfirmLeaveModalOpen$: Observable<boolean>;
  public pendingMessage$: Observable<string>;

  public readonly ModalSize = ModalSize;

  /**
   * @param {Store<MerlotState>} store
   * @memberof NavigationConfirmModalComponent
   */
  constructor(private store: Store<MerlotState>) {
    this.isConfirmLeaveModalOpen$ = this.store.select(NavigationSelectors.getConfirmLeaveModalOpen);
    this.pendingMessage$ = this.store.select(NavigationSelectors.getPendingMessage);
  }

  /**
   * cancelNavigateAway
   * @memberof NavigationConfirmModalComponent
   */
  public cancelNavigateAway() {
    this.store.dispatch(NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.BLOCKING }));
  }

  /**
   * confirmNavigateAway
   * @memberof NavigationConfirmModalComponent
   */
  public confirmNavigateAway() {
    this.store.dispatch(NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.APPROVED }));
  }
}
