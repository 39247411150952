/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

/* eslint-disable import/order */

// icons/cds-icons
import {
  addTextIcon,
  administratorIcon,
  alarmClockIcon,
  alignLeftTextIcon,
  alignRightTextIcon,
  angleDoubleIcon,
  angleIcon,
  applicationIcon,
  applicationsIcon,
  arrowIcon,
  banIcon,
  barChartIcon,
  barsIcon,
  bellIcon,
  blocksGroupIcon,
  boldIcon,
  bookIcon,
  bookmarkIcon,
  bubbleChartIcon,
  buildingIcon,
  bulletListIcon,
  calendarIcon,
  ClarityIcons as CdsIcons,
  centerTextIcon,
  chatBubbleIcon,
  checkCircleIcon,
  checkIcon,
  circleArrowIcon,
  clipboardIcon,
  clockIcon,
  cloudNetworkIcon,
  codeIcon,
  cogIcon,
  compassIcon,
  connectIcon,
  copyIcon,
  copyToClipboardIcon,
  cpuIcon,
  cursorArrowIcon,
  cursorHandOpenIcon,
  cursorMoveIcon,
  dashboardIcon,
  dataClusterIcon,
  devicesIcon,
  disconnectIcon,
  displayIcon,
  downloadIcon,
  dragHandleIcon,
  ellipsisHorizontalIcon,
  ellipsisVerticalIcon,
  employeeIcon,
  envelopeIcon,
  errorStandardIcon,
  exclamationCircleIcon,
  exclamationTriangleIcon,
  fileGroupIcon,
  fileIcon,
  filter2Icon,
  filterGridIcon,
  filterIcon,
  flagIcon,
  heatMapIcon,
  helpIcon,
  highlighterIcon,
  homeIcon,
  imageIcon,
  indentIcon,
  infoCircleIcon,
  infoStandardIcon,
  italicIcon,
  justifyTextIcon,
  keyIcon,
  launchpadIcon,
  layersIcon,
  libraryIcon,
  lineChartIcon,
  linkIcon,
  lockIcon,
  loginIcon,
  minusCircleIcon,
  minusIcon,
  mobileIcon,
  networkGlobeIcon,
  networkSettingsIcon,
  nodesIcon,
  noteIcon,
  numberListIcon,
  organizationIcon,
  outdentIcon,
  pencilIcon,
  pinIcon,
  playIcon,
  pluginIcon,
  plusCircleIcon,
  plusIcon,
  popOutIcon,
  rackServerIcon,
  refreshIcon,
  resizeIcon,
  searchIcon,
  shareIcon,
  shrinkIcon,
  sliderIcon,
  sortByIcon,
  starIcon,
  stopIcon,
  strikethroughIcon,
  subscriptIcon,
  successStandardIcon,
  superscriptIcon,
  switchIcon,
  syncIcon,
  talkBubblesIcon,
  targetIcon,
  tasksIcon,
  textColorIcon,
  timesCircleIcon,
  timesIcon,
  trashIcon,
  treeViewIcon,
  underlineIcon,
  undoIcon,
  updateIcon,
  userIcon,
  usersIcon,
  videoCameraIcon,
  viewListIcon,
  vmIcon,
  warningStandardIcon,
  windowCloseIcon,
  zoomInIcon,
  zoomOutIcon,
} from '@cds/core/icon';

// icons/chart-types
import areaChartSvg from './chart-types/area-chart.svg';
import barChartSvg from './chart-types/bar-chart.svg';
import horizontalBarChartSvg from './chart-types/horizontal-bar-chart.svg';
import lineChartSvg from './chart-types/line-chart.svg';
import metricChartSvg from './chart-types/metric-chart.svg';
import pieChartSvg from './chart-types/pie-chart.svg';
import tableChartSvg from './chart-types/table-chart.svg';
import heatMapChartSvg from './chart-types/heat-map-chart.svg';
import treeMapChartSvg from './chart-types/tree-map-chart.svg';
import groupedVerticalBarChartSvg from './chart-types/grouped-vertical-bar-chart.svg';
import stackedVerticalBarChartSvg from './chart-types/stacked-vertical-bar-chart.svg';

// icons/common
import columnEditorSvg from './common/column-editor.svg';
import selectorAutomationSplitViewCardSvg from './common/selector-automation-split-view-card.svg';
import selectorAutomationSplitViewGridSvg from './common/selector-automation-split-view-grid.svg';
import selectorAutomationSplitViewListSvg from './common/selector-automation-split-view-list.svg';
import selectorCardSvg from './common/selector-card.svg';
import selectorListSvg from './common/selector-list.svg';
import selectorSplitViewGridSvg from './common/selector-split-view-grid.svg';
import selectorSplitViewListSvg from './common/selector-split-view-list.svg';
import templatePluginSvg from './common/template-plugin.svg';
import usersSvg from './common/users.svg';
import addRuleSetSvg from './common/add-rule-set.svg';
import solidTriangleSvg from './common/solid-triangle.svg';

// icons/navigation-menu
import integrationSvg from './navigation-menu/integration.svg';
import workflowSvg from './navigation-menu/workflow.svg';

// icons/platforms
import androidSvg from './platforms/android.svg';
import appleSvg from './platforms/apple.svg';
import macosSvg from './platforms/macos.svg';
import windowsSvg from './platforms/windows.svg';

// user
import blankAvatarSvg from './blank-avatar.svg';

// icons/user-panel
import vmcLogoSvg from './user-panel/vmc-logo.svg';
import horizonCloudWhiteSvg from './user-panel/horizon-cloud-white.svg';
import ws1CloudWhiteSvg from './user-panel/ws1-cloud-white.svg';
import ws1AccessWhiteSvg from './user-panel/ws1-access-white.svg';
import ws1HubServicesWhiteSvg from './user-panel/ws1-hub-services-white.svg';
import ws1IntelligenceWhiteSvg from './user-panel/ws1-intelligence-white.svg';
import ws1UemWhiteSvg from './user-panel/ws1-uem-white.svg';

// icons/global-orchestrator
import orchestratorAction from './global-orchestrator/action.svg';
import orchestratorAutomaticTrigger from './global-orchestrator/automatic-trigger.svg';
import orchestratorCondition from './global-orchestrator/condition.svg';
import orchestratorGroup from './global-orchestrator/group.svg';
import orchestratorWorkflowSettings from './global-orchestrator/workflow-settings.svg';

// vendors/svg/roles
import auditorCircleSvg from './vendors/svg/roles/auditor-circle.svg';
import auditorSolidSvg from './vendors/svg/roles/auditor-solid.svg';
import auditorSvg from './vendors/svg/roles/auditor.svg';
import automatorCircleSvg from './vendors/svg/roles/automator-circle.svg';
import automatorSolidSvg from './vendors/svg/roles/automator-solid.svg';
import automatorSvg from './vendors/svg/roles/automator.svg';
import communicatorCircleSvg from './vendors/svg/roles/communicator-circle.svg';
import communicatorSolidSvg from './vendors/svg/roles/communicator-solid.svg';
import communicatorSvg from './vendors/svg/roles/communicator.svg';
import moderatorCircleSvg from './vendors/svg/roles/moderator-circle.svg';
import moderatorSolidSvg from './vendors/svg/roles/moderator-solid.svg';
import moderatorSvg from './vendors/svg/roles/moderator.svg';

// templates
import sensorSvg from './templates/sensor.svg';

// app integrations
import zoomIntegrationSvg from './vendors/svg/zoom-small.svg';

const iconsToSvgs: GenericObject = {
  // chart-types
  'dpa-area-chart': areaChartSvg,
  'dpa-bar-chart': barChartSvg,
  'dpa-stacked-bar-chart': stackedVerticalBarChartSvg,
  'dpa-vertical_group-chart': groupedVerticalBarChartSvg,
  'dpa-horizontal_group-chart': groupedVerticalBarChartSvg,
  'dpa-horizontal-bar-chart': horizontalBarChartSvg,
  'dpa-line-chart': lineChartSvg,
  'dpa-metric-chart': metricChartSvg,
  'dpa-pie-chart': pieChartSvg,
  'dpa-table-chart': tableChartSvg,
  'dpa-heat-map-chart': heatMapChartSvg,
  'dpa-tree-map-chart': treeMapChartSvg,

  // common
  'dpa-column-editor': columnEditorSvg,
  'dpa-selector-automation-split-view-card': selectorAutomationSplitViewCardSvg,
  'dpa-selector-automation-split-view-grid': selectorAutomationSplitViewGridSvg,
  'dpa-selector-automation-split-view-list': selectorAutomationSplitViewListSvg,
  'dpa-selector-card': selectorCardSvg,
  'dpa-selector-list': selectorListSvg,
  'dpa-selector-split-view-grid': selectorSplitViewGridSvg,
  'dpa-selector-split-view-list': selectorSplitViewListSvg,
  'dpa-template-plugin': templatePluginSvg,
  'dpa-users': usersSvg,
  'dpa-add-rule-set': addRuleSetSvg,
  'dpa-solid-triangle': solidTriangleSvg,

  // navigation-menu
  'dpa-integration': integrationSvg,
  'dpa-workflow': workflowSvg,

  //platforms
  'dpa-android': androidSvg,
  'dpa-apple': appleSvg,
  'dpa-macos': macosSvg,
  'dpa-windows': windowsSvg,

  // user
  'dpa-blank-avatar': blankAvatarSvg,

  // user-panel
  'dpa-vmc-logo': vmcLogoSvg,
  'dpa-horizon-cloud-white': horizonCloudWhiteSvg,
  'dpa-ws1-cloud-white': ws1CloudWhiteSvg,
  'dpa-ws1-access-white': ws1AccessWhiteSvg,
  'dpa-ws1-hub-services-white': ws1HubServicesWhiteSvg,
  'dpa-ws1-intelligence-white': ws1IntelligenceWhiteSvg,
  'dpa-ws1-uem-white': ws1UemWhiteSvg,

  // global-orchestrator
  'dpa-orchestrator-action': orchestratorAction,
  'dpa-orchestrator-automatic-trigger': orchestratorAutomaticTrigger,
  'dpa-orchestrator-condition': orchestratorCondition,
  'dpa-orchestrator-group': orchestratorGroup,
  'dpa-orchestrator-workflow-settings': orchestratorWorkflowSettings,

  // vendors/roles
  'dpa-auditor': auditorSvg,
  'dpa-auditor-circle': auditorCircleSvg,
  'dpa-auditor-solid': auditorSolidSvg,
  'dpa-automator': automatorSvg,
  'dpa-automator-circle': automatorCircleSvg,
  'dpa-automator-solid': automatorSolidSvg,
  'dpa-communicator': communicatorSvg,
  'dpa-communicator-circle': communicatorCircleSvg,
  'dpa-communicator-solid': communicatorSolidSvg,
  'dpa-moderator': moderatorSvg,
  'dpa-moderator-circle': moderatorCircleSvg,
  'dpa-moderator-solid': moderatorSolidSvg,

  // templates
  'dpa-sensor': sensorSvg,

  // app integrations
  'dpa-zoom': zoomIntegrationSvg,
};

const iconsToAliases = {
  // chart-types
  'dpa-bar-chart': ['dpa-vertical-chart'],
  'dpa-horizontal-bar-chart': ['dpa-horizontal-chart'],
  'dpa-pie-chart': ['dpa-donut-chart'],
  'dpa-heat-map-chart': ['dpa-heat_map-chart'],
  'dpa-tree-map-chart': ['dpa-tree_map-chart'],

  // user-panel
  'dpa-horizon-cloud-white': ['dpa-horizon-white'],
  'dpa-ws1-intelligence-white': ['dpa-intelligence-white'],
  'dpa-ws1-hub-services-white': ['dpa-hub-services-white'],
};

export const registerDpaIcons = () => {
  // Register icons to cds-icons, each icon needs to be imported
  // and added separately to keep the package bundle size low.
  CdsIcons.addIcons(
    addTextIcon,
    administratorIcon,
    alarmClockIcon,
    alignLeftTextIcon,
    alignRightTextIcon,
    angleDoubleIcon,
    angleIcon,
    applicationIcon,
    applicationsIcon,
    arrowIcon,
    banIcon,
    barChartIcon,
    barsIcon,
    bellIcon,
    blocksGroupIcon,
    boldIcon,
    bookIcon,
    bookmarkIcon,
    bubbleChartIcon,
    buildingIcon,
    bulletListIcon,
    calendarIcon,
    centerTextIcon,
    chatBubbleIcon,
    checkCircleIcon,
    checkIcon,
    checkIcon,
    circleArrowIcon,
    clipboardIcon,
    clockIcon,
    cloudNetworkIcon,
    codeIcon,
    codeIcon,
    cogIcon,
    compassIcon,
    connectIcon,
    copyIcon,
    copyToClipboardIcon,
    cpuIcon,
    cursorArrowIcon,
    cursorHandOpenIcon,
    cursorMoveIcon,
    dashboardIcon,
    dataClusterIcon,
    devicesIcon,
    disconnectIcon,
    displayIcon,
    downloadIcon,
    dragHandleIcon,
    dragHandleIcon,
    ellipsisHorizontalIcon,
    ellipsisVerticalIcon,
    employeeIcon,
    envelopeIcon,
    errorStandardIcon,
    errorStandardIcon,
    exclamationCircleIcon,
    exclamationTriangleIcon,
    fileGroupIcon,
    fileGroupIcon,
    fileIcon,
    filter2Icon,
    filterGridIcon,
    filterIcon,
    flagIcon,
    heatMapIcon,
    helpIcon,
    highlighterIcon,
    homeIcon,
    imageIcon,
    indentIcon,
    infoCircleIcon,
    infoStandardIcon,
    italicIcon,
    justifyTextIcon,
    keyIcon,
    launchpadIcon,
    layersIcon,
    libraryIcon,
    lineChartIcon,
    linkIcon,
    lockIcon,
    loginIcon,
    minusCircleIcon,
    minusIcon,
    mobileIcon,
    networkGlobeIcon,
    networkSettingsIcon,
    nodesIcon,
    noteIcon,
    numberListIcon,
    organizationIcon,
    outdentIcon,
    pencilIcon,
    pinIcon,
    playIcon,
    pluginIcon,
    plusCircleIcon,
    plusIcon,
    popOutIcon,
    rackServerIcon,
    refreshIcon,
    resizeIcon,
    searchIcon,
    shareIcon,
    shrinkIcon,
    sliderIcon,
    sortByIcon,
    starIcon,
    stopIcon,
    strikethroughIcon,
    subscriptIcon,
    successStandardIcon,
    superscriptIcon,
    switchIcon,
    syncIcon,
    talkBubblesIcon,
    targetIcon,
    tasksIcon,
    textColorIcon,
    timesCircleIcon,
    timesIcon,
    trashIcon,
    treeViewIcon,
    underlineIcon,
    undoIcon,
    updateIcon,
    userIcon,
    usersIcon,
    videoCameraIcon,
    viewListIcon,
    vmIcon,
    warningStandardIcon,
    windowCloseIcon,
    zoomInIcon,
    zoomOutIcon,
  );

  // Registring custom icons to cds-icons
  Object.entries(iconsToSvgs).forEach((iconToSvg: [string, any]) => CdsIcons.addIcons(iconToSvg));

  // Registring custom aliases to cds-icons
  Object.entries(iconsToAliases).forEach((iconToAliases: [string, string[]]) => CdsIcons.addAliases(iconToAliases));
};
