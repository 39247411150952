/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalSize } from '@dpa/ui-common/model';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { LazyLoadService } from '@dpa-shared-core/lazy-load';
import { NavigationActions, NavigationSelectors } from '@ws1c/intelligence-core/store';
import { NavigationPreviewModalButtonData, NavigationPreviewModalConfig, NavigationPreviewModalData } from '@ws1c/intelligence-models';

/**
 * NavigationPreviewModalComponent
 *
 * Adding a new navigation preview modal: https://confluence-euc.eng.vmware.com/display/ANALY/Adding+a+new+Navigation+Preview+Modal
 * @export
 * @class NavigationPreviewModalComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-navigation-preview-modal',
  templateUrl: 'navigation-preview-modal.component.html',
  styleUrls: ['navigation-preview-modal.component.scss'],
})
export class NavigationPreviewModalComponent implements OnInit, OnDestroy {
  @ViewChild('modalContent', { read: ViewContainerRef }) public modalContent: ViewContainerRef;

  public isPreviewModalOpen$: Observable<boolean>;
  public previewModalTitle$: Observable<string>;
  public buttonData: NavigationPreviewModalButtonData;
  public sub: Subscription = new Subscription();

  public readonly ModalSize = ModalSize;

  /**
   * Creates an instance of NavigationPreviewModalComponent.
   * @param {Store} store
   * @param {LazyLoadService} lazyLoadService
   * @param {Router} router
   * @memberof NavigationPreviewModalComponent
   */
  constructor(
    private store: Store,
    public lazyLoadService: LazyLoadService,
    private router: Router,
  ) {
    this.previewModalTitle$ = this.store.select(NavigationSelectors.getPreviewModalTitle);
  }

  /**
   * ngOnInit
   * @memberof NavigationPreviewModalComponent
   */
  public ngOnInit() {
    this.sub.add(
      combineLatest([this.store.select(NavigationSelectors.isPreviewModalOpen), this.router.events]).subscribe(
        ([isModalOpen, event]: [boolean, NavigationEnd]) => {
          if (isModalOpen && event instanceof NavigationEnd) {
            this.store.dispatch(NavigationActions.hidePreviewModal());
          }
        },
      ),
    );
    this.sub.add(
      this.store
        .select(NavigationSelectors.getPreviewModalData)
        .pipe(filter(Boolean), distinctUntilChanged(isEqual))
        .subscribe(({ modalType, ...props }: NavigationPreviewModalData) => {
          this.isPreviewModalOpen$ = this.store.select(NavigationSelectors.isPreviewModalOpenByType(modalType));
          this.buttonData = NavigationPreviewModalConfig.buttonDataByModalType[modalType](props?.resourceId);
          this.lazyLoadService.buildComponent(
            NavigationPreviewModalConfig.lazyComponentTypeByModalType[modalType],
            this.modalContent,
            props,
          );
        }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof NavigationPreviewModalComponent
   */
  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * closeModal
   * @memberof NavigationPreviewModalComponent
   */
  public closeModal() {
    this.store.dispatch(NavigationActions.hidePreviewModal());
  }
}
