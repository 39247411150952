/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { NgModule, Optional, SkipSelf } from '@angular/core';

import { MerlotModule } from '@dpa-shared-merlot/merlot.module';
import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { GlobalHeaderSearchComponent } from './global-header-search.component';
import {
  AppApteligentSearchCategoryState,
  AppSearchCategoryState,
  AutomationSearchCategoryState,
  DashboardSearchCategoryState,
  DeviceSearchCategoryState,
  OsSearchCategoryState,
  ReportSearchCategoryState,
  UserSearchCategoryState,
  WidgetSearchCategoryState,
} from './search-category-state';

/**
 * GlobalHeaderSearchModule contains Global Search related components
 * @class GlobalHeaderSearchModule
 */
@NgModule({
  imports: [MerlotModule, IntelligenceCommonModule],
  declarations: [GlobalHeaderSearchComponent],
  providers: [
    AppApteligentSearchCategoryState,
    AppSearchCategoryState,
    AutomationSearchCategoryState,
    DashboardSearchCategoryState,
    DeviceSearchCategoryState,
    OsSearchCategoryState,
    ReportSearchCategoryState,
    UserSearchCategoryState,
    WidgetSearchCategoryState,
  ],
  exports: [GlobalHeaderSearchComponent],
})
export class GlobalHeaderSearchModule {
  /**
   * Creates an instance of GlobalHeaderSearchModule.
   * @param {GlobalHeaderSearchModule} parentModule
   * @memberof GlobalHeaderSearchModule
   */
  constructor(@Optional() @SkipSelf() public parentModule: GlobalHeaderSearchModule) {
    if (parentModule) {
      throw new Error('GlobalHeaderSearchModule already loaded; Import in root module only.');
    }
  }
}
