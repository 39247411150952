/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppConfig } from '@ws1c/intelligence-common';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (AppConfig.SENTRY && !isDevMode()) {
  AppConfig.SENTRY.init({
    dsn: AppConfig.SENTRY_DNS,
    // Set environment based on hostname - localhost/ci/staging/sandbox/na1
    environment: document.location.hostname.split('.').shift(),
    beforeSend(event) {
      // eslint-disable-next-line
      if (!document.cookie.includes('API_URL')) {
        // API_URL will be set when the user is loggedIn
        // Do not log events in sentry if the user is not loggedIn
        return null;
      }

      return event;
    },
  });
  // generate unique transactionId and set as Sentry tag
  AppConfig.SENTRY.getCurrentScope().setTag(AppConfig.SENTRY_TRANSACTION_ID_TAG, AppConfig.SENTRY_TRANSACTION_ID);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    if (AppConfig.SENTRY) {
      AppConfig.SENTRY.captureException(err);
    } else {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  });
