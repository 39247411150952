/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { FuzzySubgroupConfig } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { orderBy } from 'lodash-es';
import { map, Observable } from 'rxjs';

import { GlobalSearchConfig } from '@dpa-components/global-header-search/global-search.config';
import { GlobalSearchActions, GlobalSearchSelectors } from '@dpa-shared-merlot';
import { SubDashboardId, SubDashboardPlatform } from '@ws1c/deem-solution/const';
import { DashboardActions, DashboardSelectors, NavigationActions } from '@ws1c/intelligence-core';
import { Device, GlobalSearchCategoryState, UserDetailPageType } from '@ws1c/intelligence-models';

/**
 * DeviceSearchCategoryState
 * @export
 * @class DeviceSearchCategoryState
 * @implements {GlobalSearchCategoryState<Device>}
 */
@Injectable()
export class DeviceSearchCategoryState implements GlobalSearchCategoryState<Device> {
  public subgroupConfig$: Observable<FuzzySubgroupConfig>;
  public isLoading$: Observable<boolean>;
  public items$: Observable<Device[]>;
  public minQueryLength: number = GlobalSearchConfig.minSearchLength;

  /**
   * constructors
   * @param {Store} store
   * @memberof DeviceSearchCategoryState
   */
  constructor(private store: Store) {
    this.items$ = this.store.select(GlobalSearchSelectors.getDevicesData);
    this.subgroupConfig$ = this.store.select(DashboardSelectors.getDashboardSubgroupConfig).pipe(
      map((subGroup: FuzzySubgroupConfig) => {
        subGroup.subgroups = orderBy(subGroup.subgroups, 'key', 'desc');
        return subGroup;
      }),
    );
    this.isLoading$ = this.store.select(GlobalSearchSelectors.isGlobalSearchLoading);
  }

  /**
   * onSearch
   * @param {string} query
   * @memberof DeviceSearchCategoryState
   */
  public onSearch(query: string) {
    query = query.trim();
    if (query.length < GlobalSearchConfig.minSearchLength) {
      return;
    }
    this.store.dispatch(GlobalSearchActions.searchDevices({ query }));
  }

  /**
   * onSelectItem
   * @param {Device} device
   * @memberof DeviceSearchCategoryState
   */
  public onSelectItem(device: Device) {
    this.store.dispatch(
      NavigationActions.setBreadCrumbs({
        breadCrumbs: [],
      }),
    );
    this.store.dispatch(
      DashboardActions.goToDeviceDetailPage({
        device,
        userDetailPageType: device.isDesktopDevice ? UserDetailPageType.DEEM_USER : undefined,
        subDashboardId: SubDashboardId.DEVICES,
        platform: SubDashboardPlatform.ALL,
      }),
    );
  }

  /**
   * keyBy
   * @param {Device} device
   * @returns {string}
   * @memberof DeviceSearchCategoryState
   */
  public keyBy(device: Device): string {
    return [device.name, device.id, device.serialNumber].join(' - ');
  }
}
