<dpa-common-menu
  *ngIf="showAvailableAdministratorsMenuItems$ | async"
  [defaultMenuItemRoute]="defaultSettingsMenuItemRoute$ | async"
  [menuItems]="availableAdministratorsMenuItems$ | async"
  [navGroupHeading]="'NAVIGATION.ADMINISTRATORS' | translate"
  [navGroupIconName]="'administrator'"
></dpa-common-menu>
<dpa-common-menu
  [defaultMenuItemRoute]="defaultSettingsMenuItemRoute$ | async"
  [menuItems]="availableSettingsMenuItems$ | async"
></dpa-common-menu>
