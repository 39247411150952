/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { TranslatePipe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store';
import { RouterExtensions } from '@ws1c/intelligence-common';
import { UserPreferenceActions, UserPreferenceUIPreferenceSelectors } from '@ws1c/intelligence-core';
import { UIPreference } from '@ws1c/intelligence-models';

/**
 * Standard Dashboard UEM V2 Toggle
 *
 * @export
 * @class StandardDashboardUemV2ToggleComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  standalone: true,
  selector: 'dpa-standard-dashboard-uem-v2-toggle',
  templateUrl: 'standard-dashboard-uem-v2-toggle.component.html',
  styleUrls: ['standard-dashboard-uem-v2-toggle.component.scss'],
  imports: [AsyncPipe, NgIf, ClarityModule, TranslatePipe],
})
export class StandardDashboardUemV2ToggleComponent implements OnInit, OnDestroy {
  public isUemV2DashboardToggleEnabledInUI: boolean;
  public subscription: Subscription = new Subscription();

  private currentUrl: string;
  private refreshPage: boolean = false;

  /**
   * StandardDashboardUemV2ToggleComponent
   * @param {Store<MerlotState>} store
   * @param {RouterExtensions} routerExt
   * @param {Router} router
   * @memberof StandardDashboardUemV2ToggleComponent
   */
  constructor(
    private store: Store<MerlotState>,
    private routerExt: RouterExtensions,
    private router: Router,
  ) {}

  /**
   * ngOnInit
   * @memberof StandardDashboardUemV2ToggleComponent
   */
  public ngOnInit() {
    this.subscription.add(
      this.store
        .select(UserPreferenceUIPreferenceSelectors.isUemV2DashboardToggleEnabled)
        .subscribe((isUemV2DashboardToggleEnabledInUI: boolean) => {
          this.isUemV2DashboardToggleEnabledInUI = isUemV2DashboardToggleEnabledInUI;
          if (this.refreshPage) {
            this.routerExt.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.routerExt.navigate([this.currentUrl]);
            });
            this.refreshPage = false;
          }
        }),
    );
  }

  /**
   * Unsubscribes from the subscription
   * @memberof StandardDashboardUemV2ToggleComponent
   */
  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * onToggleClick
   * @param {any} event
   * @memberof StandardDashboardUemV2ToggleComponent
   */
  public onToggleClick(event: any) {
    this.currentUrl = this.router.url;
    this.refreshPage = true;
    this.store.dispatch(
      UserPreferenceActions.updateUISettings({
        uiSettings: {
          [UIPreference.UI_UEM_V2_DASHBOARD_TOGGLE_ENABLED]: event?.target?.checked,
        },
      }),
    );
  }
}
