/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { DashboardActions, DashboardSelectors } from '@ws1c/intelligence-core';
import { Dashboard, GlobalSearchCategoryState } from '@ws1c/intelligence-models';

/**
 * DashboardSearchCategoryState
 * @export
 * @class DashboardSearchCategoryState
 * @implements {GlobalSearchCategoryState<Dashboard>}
 */
@Injectable()
export class DashboardSearchCategoryState implements GlobalSearchCategoryState<Dashboard> {
  public isLoading$: Observable<boolean>;
  public items$: Observable<Dashboard[]>;

  /**
   * constructor
   * @param {Store} store
   * @memberof DashboardSearchCategoryState
   */
  constructor(private store: Store) {
    this.items$ = this.store.select(DashboardSelectors.getGlobalSearchResults);
    this.isLoading$ = this.store.pipe(delay(0), select(DashboardSelectors.isDashboardSearchInProgress));
  }

  /**
   * onSearch
   * @param {string} query
   * @memberof DashboardSearchCategoryState
   */
  public onSearch(query: string) {
    this.store.dispatch(DashboardActions.searchDashboards({ query }));
  }

  /**
   * onSelectItem
   * @param {Dashboard} dashboard
   * @memberof DashboardSearchCategoryState
   */
  public onSelectItem(dashboard: Dashboard) {
    this.store.dispatch(DashboardActions.goToDashboardPage({ dashboardId: dashboard?.id }));
  }

  /**
   * keyBy
   * @param {Dashboard} dashboard
   * @returns {string}
   * @memberof DashboardSearchCategoryState
   */
  public keyBy(dashboard: Dashboard): string {
    return dashboard.name;
  }
}
