/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { from, Observable } from 'rxjs';

import { LazyComponentType, LazyFeatureStoreType } from '@ws1c/intelligence-models';

export const DYNAMIC_IMPORT_MAP: Record<LazyComponentType | LazyFeatureStoreType, Observable<GenericObject>> = {
  [LazyComponentType.AUTOMATION_NAV_PREVIEW_CONTENT]: from(
    import('@ws1c/intelligence-workflow/lazy-load/automation-navigation-preview-content/automation-navigation-preview-content.module').then(
      (m) => m.AutomationNavigationPreviewContentModule,
    ),
  ),
  [LazyComponentType.INCIDENT_NAV_PREVIEW_CONTENT]: from(
    import('@ws1c/deem-solution/lazy-load/incident-navigation-preview-content/incident-navigation-preview-content.module').then(
      (m) => m.IncidentNavigationPreviewContentModule,
    ),
  ),
  [LazyComponentType.INSIGHT_NAV_PREVIEW_CONTENT]: from(
    import('@ws1c/deem-solution/lazy-load/insight-navigation-preview-content/insight-navigation-preview-content.module').then(
      (m) => m.InsightNavigationPreviewContentModule,
    ),
  ),
  [LazyComponentType.SURVEY_NAV_PREVIEW_CONTENT]: from(
    import('@ws1c/deem-solution/lazy-load/survey-navigation-preview-content/survey-navigation-preview-content.module').then(
      (m) => m.SurveyNavigationPreviewContentModule,
    ),
  ),
  [LazyFeatureStoreType.REPORT]: from(
    import('@ws1c/intelligence-report/intelligence-report-base.module').then((m) => m.IntelligenceReportBaseModule),
  ),
};
