/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { SentryErrorHandler } from '@sentry/angular-ivy';

import { RouterExtensions } from '@ws1c/intelligence-common';
import { ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * SentryErrorHandler
 *
 * @export
 * @class AppErrorHandler
 * @extends {SentryErrorHandler}
 */
export class AppErrorHandler extends SentryErrorHandler {
  /**
   * Creates an instance of AppErrorHandler.
   * @param {RouterExtensions} router
   * @memberof AppErrorHandler
   */
  constructor(private router: RouterExtensions) {
    // configure SentryErrorHandler
    super({
      showDialog: false,
    });
  }

  /**
   * handleError
   *
   * @override
   * @param {*} error
   * @memberof AppErrorHandler
   */
  public handleError(error: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      this.router.navigate([`/${ROUTE_NAMES.PRODUCT_UPGRADE}`]);
    } else {
      super.handleError(error);
    }
  }
}
