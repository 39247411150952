<ng-container *ngLet="activeBookmarks$ | async as bookmarks">
  <dpa-common-menu
    [hidden]="!bookmarks.length"
    [navGroup]="NavigationMenuGroup.BOOKMARKS"
    [navGroupHeading]="'COMMON_ACTIONS.BOOKMARKS' | translate"
    [navGroupIconName]="'bookmark'"
    [navItemsTemplate]="navItemsTemplate"
  ></dpa-common-menu>

  <ng-template #navItemsTemplate>
    <a
      *ngFor="let bookmark of bookmarks"
      clrVerticalNavLink
      (click)="onBookmarkClick(bookmark.resourcePath)"
      class="bookmark-link"
      role="button"
    >
      <div class="bookmark-name-wrapper">
        <dpa-ellipsis-with-tooltip>
          {{ bookmark.title | translate }}
        </dpa-ellipsis-with-tooltip>
      </div>
      <button
        (click)="onRemoveBookmark($event, bookmark)"
        class="btn btn-link btn-icon btn-sm"
      >
        <cds-icon shape="times"></cds-icon>
      </button>
    </a>
  </ng-template>
</ng-container>
