/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';

import { GlobalHeaderSearchModule } from '@dpa-components/global-header-search/global-header-search.module';
import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { HeaderComponent } from './header.component';

/**
 * HeaderModule
 * @class HeaderModule
 */
@NgModule({
  declarations: [HeaderComponent],
  imports: [IntelligenceCommonModule, GlobalHeaderSearchModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
