/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { FuzzySubgroupConfig, GenericObject } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { mapValues } from 'lodash-es';
import { Observable } from 'rxjs';

import { GlobalSearchActions, GlobalSearchSelectors, OsData } from '@dpa-shared-merlot';
import { DashboardSelectors } from '@ws1c/intelligence-core';
import { COLUMN_NAMES, GlobalSearchCategoryState, StandardDashboardRequest, StandardDashboardType } from '@ws1c/intelligence-models';

/**
 * OsSearchCategoryState
 * @export
 * @class OsSearchCategoryState
 * @implements {GlobalSearchCategoryState<OsData>}
 */
@Injectable()
export class OsSearchCategoryState implements GlobalSearchCategoryState<OsData> {
  public subgroupConfig$: Observable<FuzzySubgroupConfig>;
  public isLoading$: Observable<boolean>;
  public items$: Observable<OsData[]>;
  public osMetadata: GenericObject = {};

  /**
   * constructor
   * @param {Store} store
   * @memberof OsSearchCategoryState
   */
  constructor(private store: Store) {
    this.items$ = this.store.select(GlobalSearchSelectors.getOsData);
    this.subgroupConfig$ = this.store.select(DashboardSelectors.getDashboardSubgroupConfig);
    this.isLoading$ = this.store.select(GlobalSearchSelectors.isGlobalSearchLoading);
    this.store.select(GlobalSearchSelectors.getOsSearchMetadata).subscribe((osMetadata: GenericObject) => {
      this.osMetadata = osMetadata;
    });
  }

  /**
   * onInit
   * @memberof OsSearchCategoryState
   */
  public onInit() {
    this.store.dispatch(
      GlobalSearchActions.getOsMetadata({
        request: new StandardDashboardRequest(StandardDashboardType.OS_SUMMARY),
      }),
    );
  }

  /**
   * onSearch
   * @param {string} query
   * @memberof OsSearchCategoryState
   */
  public onSearch(query: string) {
    const filterValue = query ? ` ${COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform} CONTAINS \'${query}\' ` : '';
    const searchQuery = mapValues(this.osMetadata, (osMetadata) => {
      return {
        ...osMetadata,
        filter: filterValue ? ` ${osMetadata.filter} AND ${filterValue} ` : osMetadata.filter,
      };
    });
    this.store.dispatch(GlobalSearchActions.searchOsUpdate({ query: searchQuery }));
  }

  /**
   * onSelectItem
   * @param {OsData} os
   * @memberof OsSearchCategoryState
   */
  public onSelectItem(os: OsData) {
    this.store.dispatch(GlobalSearchActions.goToOsPage({ name: os.name }));
  }

  /**
   * keyBy
   * @param {OsData} os
   * @returns {string}
   * @memberof OsSearchCategoryState
   */
  public keyBy(os: OsData): string {
    return os.name;
  }
}
