<ng-container *ngIf="navGroupHeading; else navItems">
  <clr-vertical-nav-group
    [clrVerticalNavGroupExpanded]="!isSecondaryNavCollapsed && !isGroupCollapsed"
    [class.active]="isGroupActive && (isSecondaryNavCollapsed || isGroupCollapsed)"
    (click)="onNavGroupLabelClick($event)"
  >
    <dpa-tooltip
      clrVerticalNavIcon
      [tooltipText]="navGroupHeading"
      [attr.aria-label]="navGroupHeading"
      [focusEnabled]="true"
      class="display-flex"
      role="tooltip"
    >
      <cds-icon
        *ngIf="navGroupIconName; else navGroupImage"
        [attr.shape]="navGroupIconName"
      ></cds-icon>
      <ng-template #navGroupImage>
        <div [ngClass]="[navGroupIconClass, 'nav-group-icon']"></div>
      </ng-template>
    </dpa-tooltip>
    <dpa-ellipsis-with-tooltip>
      {{ navGroupHeading }}
    </dpa-ellipsis-with-tooltip>
    <clr-vertical-nav-group-children>
      <ng-container *ngTemplateOutlet="navItems"></ng-container>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>
</ng-container>

<ng-template #navItems>
  <ng-container [ngTemplateOutlet]="navItemsTemplate || defaultNavItemsTemplate"></ng-container>
</ng-template>

<ng-template #defaultNavItemsTemplate>
  <ng-container *ngFor="let menuItem of menuItems">
    <dpa-tooltip
      *ngLet="menuItem.labelKey | translate as label"
      [tooltipText]="label"
      [attr.aria-label]="label"
      [focusEnabled]="true"
      role="tooltip"
    >
      <a
        clrVerticalNavLink
        #menuRoute="routerLinkActive"
        [routerLink]="[menuItem.route]"
        [class.active-nav]="isSideNavMenuActive(menuItem.route)"
        routerLinkActive="active"
        class="nav-link"
      >
        <div
          *ngIf="menuItem.iconClass"
          clrVerticalNavIcon
          [ngClass]="[menuItem.iconClass, 'menu-item-icon']"
          [class.selected]="menuRoute.isActive"
        ></div>
        <cds-icon
          *ngIf="menuItem.iconName"
          clrVerticalNavIcon
          [attr.shape]="menuItem.iconName"
          [class.selected]="menuRoute.isActive"
          class="menu-item-icon"
        ></cds-icon>
        <div class="menu-item-content">
          <dpa-ellipsis-with-tooltip [class.bold]="menuItem.bold">
            {{ label }}
          </dpa-ellipsis-with-tooltip>
          <div
            *ngIf="menuItem.hasBetaFlag"
            class="ml-x1 beta-flag"
          >
            {{ 'COMMON_MESSAGES.BETA' | translate }}
          </div>
        </div>
      </a>
    </dpa-tooltip>
  </ng-container>
</ng-template>
