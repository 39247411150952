<div
  *ngIf="showModal"
  class="modal"
>
  <!-- within timeout -->
  <div
    *ngIf="!isTimedOut"
    class="modal-dialog"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-content">
      <div class="modal-header">
        <button
          (click)="startWatching()"
          class="close"
          type="button"
        >
          <cds-icon
            aria-hidden="true"
            shape="window-close"
          ></cds-icon>
        </button>
        <h3 class="modal-title">
          {{ 'COMMON_MESSAGES.SESSION_TIMEOUT' | translate }}
        </h3>
      </div>
      <div class="modal-body">
        <div
          dpaAnimateSvg
          [animateOptions]="sessionTimeoutAnimationConfig"
        ></div>
        <div class="countdown">
          <div class="counter-left">
            {{ idleTimerMinute | number: '2.0-0' }}
          </div>
          <div class="counter-right">
            {{ idleTimerSecond | number: '2.0-0' }}
          </div>
          <p class="counter-desc">
            <span class="left">
              {{ 'COMMON_MESSAGES.MINUTES_IN_SHORT' | translate | uppercase }}
            </span>
            <span class="right">
              {{ 'COMMON_MESSAGES.SECONDS_IN_SHORT' | translate | uppercase }}
            </span>
          </p>
        </div>
        <p class="timeout-msg">
          {{ 'COMMON_MESSAGES.SESSION_TIMEOUT_MESSAGE' | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <button
          (click)="idleLogout()"
          type="button"
          class="btn btn-outline"
        >
          {{ 'COMMON_ACTIONS.LOGOUT' | translate }}
        </button>
        <button
          (click)="startWatching()"
          type="button"
          class="btn btn-primary"
        >
          {{ 'COMMON_ACTIONS.CONTINUE' | translate }}
        </button>
      </div>
    </div>
  </div>

  <!-- after timeout -->
  <div
    *ngIf="isTimedOut"
    class="modal-dialog"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">
          {{ 'COMMON_MESSAGES.SESSION_TIMEOUT' | translate }}
        </h3>
      </div>
      <div class="modal-body">
        <div class="session-expired"></div>
        <div class="countdown expired">
          <div class="counter-left">
            {{ idleTimerMinute | number: '2.0-0' }}
          </div>
          <div class="counter-right">
            {{ idleTimerSecond | number: '2.0-0' }}
          </div>
          <p class="counter-desc">
            <span class="left">
              {{ 'COMMON_MESSAGES.MINUTES_IN_SHORT' | translate | uppercase }}
            </span>
            <span class="right">
              {{ 'COMMON_MESSAGES.SECONDS_IN_SHORT' | translate | uppercase }}
            </span>
          </p>
        </div>
        <p class="timeout-msg">
          {{ 'COMMON_MESSAGES.SESSION_TIMEOUT_EXPIRED_MESSAGE' | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <button
          (click)="gotoLogin()"
          type="button"
          class="btn btn-primary"
        >
          {{ 'COMMON_ACTIONS.LOGIN' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="showModal"
  class="modal-backdrop"
  aria-hidden="true"
></div>
