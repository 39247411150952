/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { AppLoadsActions, AppLoadsSelectors } from '@dpa-shared-merlot';
import { App, AppRegistration, GlobalSearchCategoryState, Integration } from '@ws1c/intelligence-models';

/**
 * AppApteligentSearchCategoryState
 * @export
 * @class AppApteligentSearchCategoryState
 * @implements {GlobalSearchCategoryState<AppRegistration>}
 */
@Injectable()
export class AppApteligentSearchCategoryState implements GlobalSearchCategoryState<AppRegistration> {
  public isLoading$: Observable<boolean>;
  public items$: Observable<AppRegistration[]>;

  /**
   * constructor
   * @param {Store} store
   * @memberof AppApteligentSearchCategoryState
   */
  constructor(private store: Store) {
    this.items$ = this.store.select(AppLoadsSelectors.getAllRegisteredApps);
    // delay(0) is added to fix the ExpressionChangedAfterItHasBeenCheckedError which occurs when the two or more components
    // listen to same observable and try to update the UI
    this.isLoading$ = this.store.pipe(delay(0), select(AppLoadsSelectors.isLoadingAppList));
  }

  /**
   * onSearch
   * @param {string} query
   * @memberof AppApteligentSearchCategoryState
   */
  public onSearch(query: string) {
    this.store.dispatch(AppLoadsActions.searchAllRegisteredApps({ query }));
  }

  /**
   * onSelectItem
   * @param {AppRegistration} app
   * @memberof AppApteligentSearchCategoryState
   */
  public onSelectItem(app: AppRegistration) {
    const selectedApp = new App({
      id: app.packageId,
      apteligentAppId: app.apteligentAppId,
      name: app.name,
      platform: app.platform,
      integration: Integration.APTELIGENT,
      isProductivityApp: app.isProductivityApp,
    });
    this.store.dispatch(AppLoadsActions.goToAppDetailPage({ app: selectedApp }));
  }

  /**
   * keyBy
   * @param {AppRegistration} app
   * @returns {string}
   * @memberof AppApteligentSearchCategoryState
   */
  public keyBy(app: AppRegistration): string {
    return app.packageId ? `${app.name} - ${app.packageId}` : app.name;
  }
}
