/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { NavigationMenuSelectors } from '@ws1c/intelligence-core';
import { MenuItem } from '@ws1c/intelligence-models';

/**
 * Settings Menu Component
 *
 * @export
 * @class SettingsMenuComponent
 */
@Component({
  selector: 'dpa-settings-menu',
  templateUrl: 'settings-menu.component.html',
  styleUrls: ['settings-menu.component.scss'],
})
export class SettingsMenuComponent {
  public availableAdministratorsMenuItems$: Observable<MenuItem[]>;
  public availableSettingsMenuItems$: Observable<MenuItem[]>;
  public defaultSettingsMenuItemRoute$: Observable<string>;
  public showAvailableAdministratorsMenuItems$: Observable<boolean>;

  /**
   * Creates an instance of SettingsMenuComponent.
   * @param {Store<MerlotState>} store - Merlot Store observable instance
   * @memberof SettingsMenuComponent
   */
  constructor(private store: Store<MerlotState>) {
    this.availableAdministratorsMenuItems$ = this.store.select(NavigationMenuSelectors.availableAdministratorsMenuItems);
    this.availableSettingsMenuItems$ = this.store.select(NavigationMenuSelectors.availableSettingsMenuItems);
    this.defaultSettingsMenuItemRoute$ = this.store.select(NavigationMenuSelectors.defaultSettingsMenuItemRoute);
    this.showAvailableAdministratorsMenuItems$ = this.store.select(NavigationMenuSelectors.showAvailableAdministratorsMenuItems);
  }
}
