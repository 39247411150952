/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AppConfig } from '@ws1c/intelligence-common';
import { AcceptTrial } from '@ws1c/intelligence-models';

/**
 * StartTrialComponent
 * @export
 * @class StartTrialComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-start-trial',
  templateUrl: 'start-trial.component.html',
  styleUrls: ['start-trial.component.scss'],
})
export class StartTrialComponent implements OnChanges {
  @Input() public trialUserContactDetails: AcceptTrial;
  @Output() public onFormInit: EventEmitter<UntypedFormGroup> = new EventEmitter();

  public trialForm: UntypedFormGroup;
  public TRIAL_DAYS = AppConfig.TRIAL_DAYS;
  public PHONE_MIN_DIGITS = 10;
  private tenDigitPhone = new RegExp(`(.*\\d.*){${this.PHONE_MIN_DIGITS},}`);

  /**
   * @param {UntypedFormBuilder} fb
   * @memberof StartTrialComponent
   */
  constructor(private fb: UntypedFormBuilder) {
    this.trialForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      title: ['', Validators.required],
      companyName: ['', Validators.required],
      postalAddress: this.fb.group({
        address: [''],
        state: [''],
        city: ['', Validators.required],
        country: ['', Validators.required],
        postalCode: ['', Validators.required],
      }),
      phoneNumber: ['', [Validators.required, Validators.pattern(this.tenDigitPhone)]],
    });
  }

  /**
   * Populates trialForm group with changes from trialUserContactDetails input data
   * @param {SimpleChanges} changes - Holds map of changed input property name to value
   * @memberof StartTrialComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.trialUserContactDetails) {
      if (!this.trialUserContactDetails || !this.trialUserContactDetails.postalAddress) {
        this.trialUserContactDetails = new AcceptTrial({
          ...this.trialUserContactDetails,
          postalAddress: {},
        });
      }
      this.trialForm.patchValue(this.trialUserContactDetails);
      this.onFormInit.emit(this.trialForm);
    }
  }

  /**
   * showRequiredWarning
   * @param {string} fieldName
   * @returns {boolean}
   * @memberof StartTrialComponent
   */
  public showRequiredWarning(fieldName: string): boolean {
    const formControl = this.trialForm.get(fieldName);
    return formControl.invalid && (formControl.dirty || formControl.touched);
  }
}
