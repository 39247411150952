<nav
  [clr-nav-level]="1"
  class="subnav"
  role="navigation"
>
  <ul class="nav">
    <li
      *ngFor="let menuItem of navMenuItems$ | async"
      class="nav-item"
    >
      <a
        [routerLink]="[navUrlByMenuItem[menuItem]]"
        [attr.label]="labelKeyByNavMenuItem[menuItem] | translate"
        class="nav-link"
        routerLinkActive="active"
      >
        {{ labelKeyByNavMenuItem[menuItem] | translate }}
      </a>
    </li>
  </ul>
</nav>
