/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FullPageElementService, unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { RouterExtensions } from '@ws1c/intelligence-common';
import {
  AuthService,
  NavigationMenuSelectors,
  OrgSelectors,
  RbacSelectors,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceSelectors,
  UserPreferenceTrialInfoSelectors,
  UserPreferenceUIPreferenceSelectors,
} from '@ws1c/intelligence-core';
import { AlertBannerTarget, ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * This class represents the main application component.
 * @export
 * @class AppComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('contentContainer', { static: true }) public contentContainer;

  public ALERT_BANNER_TARGET = AlertBannerTarget;

  public isNavigationRestricted$: Observable<boolean>;
  public showSecondaryNavigation$: Observable<boolean>;
  public hasUserScopes$: Observable<boolean>;
  public isBrownfieldNotificationModalShown$: Observable<boolean>;
  public isRequestCloudAccountModalOpen$: Observable<boolean>;
  public isRemediationMode$: Observable<boolean>;
  public isStandaloneApteligentEnabled$: Observable<boolean>;
  public showCookiePolicyBanner$: Observable<boolean>;
  public isNotificationEnabled$: Observable<boolean>;

  public hideTrialBanner;
  public isWorkspaceOneCloudOrg: boolean;

  public readonly APPLICATION_MODE = {
    WORKSPACE_ONE: 'Workspace ONE',
    WORKSPACE_ONE_INTELLIGENCE: 'Workspace ONE Intelligence',
  };

  private subs: Subscription[];

  /**
   * Creates an instance of AppComponent.
   * @param {AuthService} authService
   * @param {FullPageElementService} fullPageElementService
   * @param {Store} store
   * @param {RouterExtensions} routerExt
   * @param {Title} titleService
   * @memberof AppComponent
   */
  constructor(
    private authService: AuthService,
    private fullPageElementService: FullPageElementService,
    private store: Store,
    private routerExt: RouterExtensions,
    private titleService: Title,
  ) {
    this.isNavigationRestricted$ = this.authService.isNavigationRestricted$;
    this.hasUserScopes$ = this.store.select(RbacSelectors.hasUserScopes);
    this.isBrownfieldNotificationModalShown$ = this.store.select(UserPreferenceSelectors.isBrownfieldNotificationModalShown);
    this.isRequestCloudAccountModalOpen$ = this.store.select(UserPreferenceSelectors.isRequestCloudAccountModalOpen);
    this.isStandaloneApteligentEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isStandaloneApteligentEnabled);
    this.showSecondaryNavigation$ = this.store.select(NavigationMenuSelectors.showSecondaryNavigation);
    this.isNotificationEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isNotificationEnabled);
    this.showCookiePolicyBanner$ = this.store.select(UserPreferenceUIPreferenceSelectors.showCookiePolicyBanner).pipe(
      filter((showCookiePolicyBanner: boolean) => !!showCookiePolicyBanner),
      take(1),
    );
  }

  /**
   * Handle landing page when transiton from Consoles before 9.2.3 to our app
   *
   * @memberof AppComponent
   */
  public ngOnInit() {
    this.fullPageElementService.setFullPageElement(this.contentContainer);
    this.subs = [
      combineLatest([this.routerExt.url$, this.store.select(UserPreferenceTrialInfoSelectors.isTrialBannerHidden)]).subscribe(
        ([url, isTrialBannerHidden]: [string, boolean]) => {
          this.hideTrialBanner = url.endsWith(ROUTE_NAMES.ACCESS_DENIED) || isTrialBannerHidden;
        },
      ),
      this.store.select(OrgSelectors.isWorkspaceOneCloudOrg).subscribe((isWorkspaceOneCloudOrg: boolean) => {
        this.isWorkspaceOneCloudOrg = isWorkspaceOneCloudOrg;
        this.titleService.setTitle(
          isWorkspaceOneCloudOrg ? this.APPLICATION_MODE.WORKSPACE_ONE : this.APPLICATION_MODE.WORKSPACE_ONE_INTELLIGENCE,
        );
      }),
    ];
  }

  /**
   * ngOnDestroy
   *
   * @memberof AppComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.subs);
  }

  /**
   * Getter appTitle
   *
   * @readonly
   * @returns {string}
   * @memberof AppComponent
   */
  public get appTitle(): string {
    return this.titleService.getTitle();
  }
}
