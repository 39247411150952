/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ModalSize, unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store';
import { AppConfig, I18NService } from '@ws1c/intelligence-common';
import { UserPreferenceActions, UserPreferenceTrialInfoSelectors, UserPreferenceUserConfigSelectors } from '@ws1c/intelligence-core';
import { AcceptTrial, IntroModalMode, PostalAddress } from '@ws1c/intelligence-models';

/**
 * IntroModalComponent
 * @export
 * @class IntroModalComponent
 */
@Component({
  selector: 'dpa-intro-modal',
  templateUrl: 'intro-modal.component.html',
  styleUrls: ['intro-modal.component.scss'],
})
export class IntroModalComponent implements OnInit, OnDestroy {
  @Input() public trialUserContactDetails: AcceptTrial;

  public INTRO_MODAL_MODE: typeof IntroModalMode = IntroModalMode;
  public TRIAL_DAYS = AppConfig.TRIAL_DAYS;

  public introModalMode$: Observable<IntroModalMode>;
  public introModalOpen$: Observable<boolean>;
  public isFetchingTrialUserContactDetails$: Observable<boolean>;
  public trialUserContactDetails$: Observable<AcceptTrial>;

  public introModalMode: IntroModalMode;
  public isFetchingTrialUserContactDetails: boolean;
  public trialAnimationConfig = {
    path: 'assets/svg-animations/trial-welcome.json',
  };

  public readonly ModalSize = ModalSize;

  private trialUserForm: UntypedFormGroup;
  private subs: Subscription[];

  /**
   * Initializes instance of IntroModalComponent
   * @param {Store<MerlotState>} store
   * @param {I18NService} i18nService
   * @memberof IntroModalComponent
   */
  constructor(
    private store: Store<MerlotState>,
    private i18nService: I18NService,
  ) {
    this.introModalMode$ = this.store.select(UserPreferenceUserConfigSelectors.getIntroModalMode);
    this.introModalOpen$ = this.store.select(UserPreferenceUserConfigSelectors.getIntroModalOpen);
    this.isFetchingTrialUserContactDetails$ = this.store.select(UserPreferenceTrialInfoSelectors.isFetchingTrialUserContactDetails);
    this.trialUserContactDetails$ = this.store.select(UserPreferenceTrialInfoSelectors.trialUserContactDetails);
  }

  /**
   * ngOnInit
   * @memberof IntroModalComponent
   */
  public ngOnInit() {
    this.subs = [
      this.introModalMode$.subscribe((introModalMode: IntroModalMode) => {
        this.introModalMode = introModalMode;
      }),
      this.isFetchingTrialUserContactDetails$.subscribe((isFetchingTrialUserContactDetails: boolean) => {
        this.isFetchingTrialUserContactDetails = isFetchingTrialUserContactDetails;
      }),
    ];
  }

  /**
   * ngOnDestroy
   * @memberof IntroModalComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.subs);
  }

  /**
   * closeIntroModal
   * @memberof IntroModalComponent
   */
  public closeIntroModal() {
    this.store.dispatch(UserPreferenceActions.closeIntroModal());
  }

  /**
   * isPrimaryTrialButtonDisabled
   * @returns {boolean}
   * @memberof IntroModalComponent
   */
  public isPrimaryTrialButtonDisabled(): boolean {
    return !this.trialUserForm || this.trialUserForm.invalid;
  }

  /**
   * primaryTrialSubmit
   * @memberof IntroModalComponent
   */
  public primaryTrialSubmit() {
    const postalAddress = new PostalAddress(this.trialUserForm.value.postalAddress);
    const formData: AcceptTrial = Object.assign(new AcceptTrial(this.trialUserForm.value), { postalAddress });
    this.store.dispatch(UserPreferenceActions.acceptTrial({ formData }));
  }

  /**
   * onTrialFormInit
   * @param {UntypedFormGroup} trialForm
   * @memberof IntroModalComponent
   */
  public onTrialFormInit(trialForm: UntypedFormGroup) {
    this.trialUserForm = trialForm;
  }

  /**
   * getWs1IntelligenceLabel
   * @returns {string}
   * @memberof IntroModalComponent
   */
  public getWs1IntelligenceLabel(): string {
    const translatedLabel = this.i18nService.translate('COMMON_MESSAGES.WS1_INTELLIGENCE');
    return `<b>${translatedLabel}</b>`;
  }
}
