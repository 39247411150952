<dpa-modal
  [modalClosable]="false"
  [modalOpen]="cspAccountRequestStatus && (isBrownfieldNotificationModalOpen$ | async)"
  [modalSize]="ModalSize.XL"
>
  <dpa-modal-title>
    {{ 'BROWNFIELD_NOTIFICATION.MODAL_TITLE' | translate }}
  </dpa-modal-title>
  <div class="modal-body">
    <dpa-alert-banner [target]="ALERT_BANNER_TARGET.MODAL"></dpa-alert-banner>
    <span>
      {{ 'BROWNFIELD_NOTIFICATION.MODAL_DESCRIPTION' | translate }}
    </span>
    <div class="clr-row clr-justify-content-around mt-x4">
      <div
        *ngIf="showRequestCloudAccountOption()"
        class="request-cloud-account-content mb-x1"
      >
        <dpa-request-cloud-account></dpa-request-cloud-account>
      </div>

      <div
        *ngIf="showLoginWithCloudOption()"
        class="login-with-cloud-content"
      >
        <span class="login-with-cloud-heading">
          {{ 'BROWNFIELD_NOTIFICATION.LOGIN_WITH_CLOUD_HEADING' | translate }}
        </span>
        <div class="clr-row clr-justify-content-center pv-x4">
          <div class="clr-col-4 csp-account-granted-img"></div>
          <div class="clr-col-8 login-with-cloud-details">
            <div *ngIf="!isUserCspAccountMerged; else showCloudLoginDescription">
              <p
                [innerHTML]="
                  'BROWNFIELD_NOTIFICATION.LOGIN_WITH_CLOUD_DESCRIPTION'
                    | translate: { workspaceOneIntelligenceDocUrl: workspaceOneIntelligenceDocUrl }
                "
              ></p>
            </div>
            <div class="pt-x4">
              <a
                [href]="loginWithCloudUrl"
                class="btn btn-primary"
                rel="noopener"
              >
                {{ 'BROWNFIELD_NOTIFICATION.LOGIN_WITH_CLOUD' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="isCspAccountRequestAlreadySubmitted()"
        class="request-submitted-content"
      >
        <span class="request-submitted-heading">
          {{ 'BROWNFIELD_NOTIFICATION.REQUEST_SUBMITTED_HEADING' | translate }}
        </span>
        <div class="clr-row clr-justify-content-center pv-x3">
          <div class="clr-col-4 csp-account-request-img"></div>
          <div class="clr-col-8">
            <span>
              {{ 'BROWNFIELD_NOTIFICATION.REQUEST_SUBMITTED_DESCRIPTION' | translate }}
            </span>
            <div>
              <span>
                {{ cspAccountRequestEmail }}
              </span>
              <button
                (click)="setEditEmailMode(true)"
                class="btn btn-link btn-icon"
              >
                <cds-icon
                  shape="pencil"
                  size="15"
                ></cds-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngLet="remainingBrownfieldTransitionDays$ | async as remainingBrownfieldTransitionDays"
    class="modal-footer clr-row clr-justify-content-between"
  >
    <div class="pl-x3">
      <span
        *ngIf="(isBrownfieldLimitedAvailabilityEnabled$ | async) === false && remainingBrownfieldTransitionDays > 0"
        class="counter"
      >
        {{ 'BROWNFIELD_NOTIFICATION.COUNTER' | translate: { count: remainingBrownfieldTransitionDays } }}
      </span>
    </div>
    <button
      (click)="closeBrownfieldNotificationModal()"
      class="btn btn-link btn-sm"
      type="button"
    >
      {{
        (isCspAccountRequestAlreadySubmitted
          ? 'BROWNFIELD_NOTIFICATION.CONTINUE_TO_INTELLIGENCE'
          : 'BROWNFIELD_NOTIFICATION.REMIND_ME_LATER'
        ) | translate
      }}
    </button>
  </div>
</dpa-modal>

<ng-template #showCloudLoginDescription>
  <p>
    {{ 'BROWNFIELD_NOTIFICATION.LOGIN_WITH_MERGED_ACCOUNT_DESCRIPTION' | translate }}
  </p>
</ng-template>
