/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { FuzzySubgroupConfig } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GlobalSearchActions, GlobalSearchSelectors } from '@dpa-shared-merlot';
import { AutomationCommonActions, AutomationCommonSelectors } from '@ws1c/intelligence-core';
import { Automation, Category, GlobalSearchCategoryState } from '@ws1c/intelligence-models';

/**
 * AutomationSearchCategoryState
 * @export
 * @class AutomationSearchCategoryState
 * @implements {GlobalSearchCategoryState<Automation>}
 */
@Injectable()
export class AutomationSearchCategoryState implements GlobalSearchCategoryState<Automation> {
  public subgroupConfig$: Observable<FuzzySubgroupConfig>;
  public isLoading$: Observable<boolean>;
  public items$: Observable<Automation[]>;
  public categoryLabelsByName: any = {};

  /**
   * constructor
   * @param {Store} store
   * @memberof AutomationSearchCategoryState
   */
  constructor(private store: Store) {
    this.items$ = this.store.select(GlobalSearchSelectors.getAutomationsData);
    this.subgroupConfig$ = this.store.select(AutomationCommonSelectors.getAutomationSubgroupConfig);
    this.isLoading$ = this.store.select(GlobalSearchSelectors.isGlobalSearchLoading);
    this.store.select(AutomationCommonSelectors.getAutomationCategoriesState).subscribe((categories: Category[]) => {
      categories.forEach((cat: Category) => {
        this.categoryLabelsByName[cat.name] = cat.label;
      });
    });
  }

  /**
   * onSearch
   * @param {string} query
   * @memberof AutomationSearchCategoryState
   */
  public onSearch(query: string) {
    this.store.dispatch(GlobalSearchActions.searchAutomations({ query }));
  }

  /**
   * onSelectItem
   * @param {Automation} automation
   * @memberof AutomationSearchCategoryState
   */
  public onSelectItem(automation: Automation) {
    this.store.dispatch(
      AutomationCommonActions.goToAutomationDetailsPage({
        automationId: automation.id,
      }),
    );
  }

  /**
   * keyBy
   * @param {Automation} automation
   * @returns {string}
   * @memberof AutomationSearchCategoryState
   */
  public keyBy(automation: Automation): string {
    return automation.name;
  }
}
