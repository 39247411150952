/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClrLoadingState } from '@clr/angular';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { UserAdminAccountActions, UserAdminAccountSelector } from '@ws1c/intelligence-core';

/**
 * RequestCloudAccountComponent
 * @export
 * @class RequestCloudAccountComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-request-cloud-account',
  templateUrl: 'request-cloud-account.component.html',
  styleUrls: ['request-cloud-account.component.scss'],
})
export class RequestCloudAccountComponent implements OnInit, OnDestroy {
  @Input() public showTitle: boolean = true;
  public isRequestForCspAccountInProgress$: Observable<boolean>;
  public cspAccountRequestEmail: string;
  public isCspAccountWithEmailNotExists: boolean;
  public requestCspAccountForm: FormGroup;
  public subscription: Subscription = new Subscription();
  public CLR_LOADING_STATE: typeof ClrLoadingState = ClrLoadingState;

  /**
   * Creates instance of RequestCloudAccountComponent
   * @param {Store<MerlotState>} store - Merlot Store observable instance
   * @param {FormBuilder} formBuilder - Form Builder instance
   * @memberof RequestCloudAccountComponent
   */
  constructor(
    private store: Store<MerlotState>,
    private formBuilder: FormBuilder,
  ) {
    this.requestCspAccountForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.isRequestForCspAccountInProgress$ = this.store.select(UserAdminAccountSelector.isRequestForCspAccountInProgress);
  }

  /**
   * ngOnInit
   * Registers listeners for isCspAccountWithEmailNotExists and getCspAccountRequestEmail
   * @memberof RequestCloudAccountComponent
   */
  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(UserAdminAccountSelector.isCspAccountWithEmailNotExists).subscribe((isCspAccountWithEmailNotExists: boolean) => {
        this.isCspAccountWithEmailNotExists = isCspAccountWithEmailNotExists;
      }),
    );
    this.subscription.add(
      this.store.select(UserAdminAccountSelector.getCspAccountRequestEmail).subscribe((cspAccountRequestEmail: string) => {
        this.cspAccountRequestEmail = cspAccountRequestEmail;
      }),
    );
  }

  /**
   * Unsubscribes from the subscription
   * @memberof RequestCloudAccountComponent
   */
  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Returns true if submit request for csp account button need to be disabled
   * @returns {boolean}
   * @memberof RequestCloudAccountComponent
   */
  public isSubmitRequestForCspAccountButtonDisabled() {
    return this.email?.invalid || !this.isCspAccountWithEmailNotExists || this.email?.value === this.cspAccountRequestEmail;
  }

  /**
   * Dispatches action to submit request for csp account
   * @memberof RequestCloudAccountComponent
   */
  public submitRequestForCspAccount() {
    this.store.dispatch(UserAdminAccountActions.submitRequestForCspAccount({ email: this.email.value }));
  }

  /**
   * Returns flag to indicate if email form control is valid or not
   * @returns {boolean}
   * @memberof RequestCloudAccountComponent
   */
  public get isEmailInvalid(): boolean {
    return this.email.invalid && (this.email.touched || this.email.dirty);
  }

  /**
   * Returns email form control
   * @returns {AbstractControl}
   * @memberof RequestCloudAccountComponent
   */
  public get email(): AbstractControl {
    return this.requestCspAccountForm.get('email');
  }

  /**
   * Dispatches action to get request for CSP account status
   * @memberof RequestCloudAccountComponent
   */
  public continueWithExistingAccount() {
    this.store.dispatch(UserAdminAccountActions.getCspAccountRequest());
  }
}
