/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Route } from '@angular/router';
import { RoutePage } from '@ws1c/intelligence-common';

import { ROUTE_SEGMENTS } from '@ws1c/intelligence-models';
import { ProductUpgradeModalComponent } from './pages/product-upgrade-modal/product-upgrade-modal.component';

/**
 * Routes for Application
 */
export const routes: Route[] = [
  {
    path: ROUTE_SEGMENTS.WORKSPACE,
    data: {
      page: RoutePage.definePage('NAVIGATION.WORKSPACE', { disableLink: true }),
    },
    loadChildren: () => import('@dpa-components/pages/workspace/workspace.module').then((route) => route.WorkspaceModule),
  },
  {
    path: ROUTE_SEGMENTS.MARKETPLACE,
    data: {
      page: RoutePage.definePage('NAVIGATION.MARKETPLACE', { disableLink: true }),
    },
    loadChildren: () => import('@dpa-components/pages/marketplace/marketplace.module').then((route) => route.MarketplaceModule),
  },
  {
    path: ROUTE_SEGMENTS.LOGIN,
    loadChildren: () => import('@dpa-components/pages/login/login.module').then((route) => route.LoginModule),
  },
  {
    path: ROUTE_SEGMENTS.ACCESS_DENIED,
    loadChildren: () => import('@dpa-components/pages/access-denied/access-denied.module').then((route) => route.AccessDeniedModule),
  },
  {
    path: ROUTE_SEGMENTS.ACCESS_RESTRICTED,
    loadChildren: () =>
      import('@dpa-components/pages/access-restricted/access-restricted.module').then((route) => route.AccessRestrictedModule),
  },
  {
    path: ROUTE_SEGMENTS.ACCEPT_EULA,
    loadChildren: () => import('@dpa-components/pages/accept-eula/accept-eula.module').then((route) => route.AcceptEulaModule),
  },
  {
    path: ROUTE_SEGMENTS.TRIAL_ENDED,
    loadChildren: () => import('@dpa-components/pages/trial-ended/trial-ended.module').then((route) => route.TrialEndedModule),
  },
  {
    path: ROUTE_SEGMENTS.CLOUD_ACCOUNT_RESTRICTED,
    loadChildren: () =>
      import('@dpa-components/pages/brownfield-migrated-access-restricted/brownfield-migrated-access-restricted.module').then(
        (route) => route.BrownfieldMigratedAccessRestrictedModule,
      ),
  },
  {
    path: `${ROUTE_SEGMENTS.COMPLIANCE}/${ROUTE_SEGMENTS.TENANTS}/:id`,
    loadChildren: () => import('@dpa-components/compliance/compliance.module').then((route) => route.ComplianceModule),
  },
  {
    path: ROUTE_SEGMENTS.COOKIE_POLICY,
    loadChildren: () => import('@dpa-components/pages/cookie-policy/cookie-policy.module').then((route) => route.CookiePolicyModule),
  },
  {
    path: `${ROUTE_SEGMENTS.INTEGRATIONS}/${ROUTE_SEGMENTS.WORKFLOW_CONNECTORS}`,
    data: {
      page: RoutePage.definePage('NAVIGATION.INTEGRATIONS', {
        disableLink: true,
      }),
    },
    loadChildren: () =>
      import('@ws1c/intelligence-connector/intelligence-connector.module').then((route) => route.IntelligenceConnectorModule),
  },
  {
    path: ROUTE_SEGMENTS.INTEGRATIONS,
    data: {
      page: RoutePage.definePage('NAVIGATION.INTEGRATIONS', {
        disableLink: true,
      }),
    },
    loadChildren: () => import('@dpa-components/pages/integrations/integrations.module').then((route) => route.IntegrationsModule),
  },
  {
    path: ROUTE_SEGMENTS.ONBOARDING,
    loadChildren: () => import('@dpa-components/pages/onboarding-page/onboarding-page.module').then((route) => route.OnboardingPageModule),
  },
  {
    path: ROUTE_SEGMENTS.ORGANIZATION,
    loadChildren: () => import('@dpa-components/pages/organization/organization.module').then((route) => route.OrganizationModule),
  },
  {
    path: ROUTE_SEGMENTS.ORG_DISABLED,
    loadChildren: () => import('@dpa-components/pages/org-disabled/org-disabled.module').then((route) => route.OrgDisabledModule),
  },
  {
    path: ROUTE_SEGMENTS.PROFILE,
    loadChildren: () => import('@dpa-components/pages/profile/profile.module').then((route) => route.ProfileModule),
  },
  {
    path: ROUTE_SEGMENTS.RESOURCE_NOT_FOUND,
    loadChildren: () =>
      import('@dpa-components/pages/resource-not-found/resource-not-found.module').then((route) => route.ResourceNotFoundModule),
  },
  {
    path: ROUTE_SEGMENTS.SETTINGS,
    loadChildren: () => import('@dpa-components/pages/settings/settings.module').then((route) => route.SettingsModule),
  },
  {
    path: ROUTE_SEGMENTS.SHARE,
    loadChildren: () => import('@dpa-components/pages/non-admin/non-admin.module').then((route) => route.NonAdminModule),
  },
  {
    path: ROUTE_SEGMENTS.NOTIFICATION,
    loadChildren: () => import('@ws1c/notification/notification.module').then((route) => route.NotificationModule),
  },
  {
    path: ROUTE_SEGMENTS.PRODUCT_UPGRADE,
    component: ProductUpgradeModalComponent,
  },
  // Must be the last one to catch invalid path
  // It's also a default landing page
  { path: '**', redirectTo: ROUTE_SEGMENTS.WORKSPACE },
];
