/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FullPageElementService } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { IntelNotification, NotificationConstants, NotificationViewType } from '@ws1c/notification/model';
import { NotificationCenterActions, NotificationCenterSelectors, NotificationState } from '@ws1c/notification/store';

/**
 * BannerNotificationComponent
 *
 * @export
 * @class BannerNotificationComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-banner-notification',
  templateUrl: 'banner-notification.component.html',
  styleUrls: ['banner-notification.component.scss'],
})
export class BannerNotificationComponent implements OnInit, OnDestroy {
  public notifications$: Observable<IntelNotification[]>;
  public subs: Subscription = new Subscription();

  public readonly SEVERITY_ALERT_TYPE_MAPPING = NotificationConstants.NOTIFICATION_SEVERITY_ALERT_TYPE_MAPPING;

  private timeout: ReturnType<typeof setTimeout>;

  /**
   * Creates an instance of BannerNotificationComponent.
   * @param {Store<NotificationState>} store
   * @param {FullPageElementService} fullPageElementService
   * @memberof BannerNotificationComponent
   */
  constructor(
    private store: Store<NotificationState>,
    private fullPageElementService: FullPageElementService,
  ) {
    this.notifications$ = this.store.select(NotificationCenterSelectors.bannerNotifications);
  }

  /**
   * ngOnInit
   * @memberof BannerNotificationComponent
   */
  public ngOnInit() {
    this.store.dispatch(NotificationCenterActions.getBannerNotifications());
    this.subs.add(
      this.notifications$.subscribe((notifications: IntelNotification[]) => {
        if (notifications?.length) {
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.fullPageElementService.resizeContainer();
          }, 10);
        }
      }),
    );
  }

  /**
   * ngOnDestroy
   * @memberof BannerNotificationComponent
   */
  public ngOnDestroy() {
    clearTimeout(this.timeout);
    this.subs.unsubscribe();
  }

  /**
   * dismissAlert
   * @param {string} id
   * @memberof BannerNotificationComponent
   */
  public dismissAlert(id: string) {
    this.store.dispatch(
      NotificationCenterActions.dismissNotifications({
        notificationIds: [id],
        view: NotificationViewType.BANNER,
      }),
    );
  }

  /**
   * onActionClick
   * @param {IntelNotification} notification
   * @memberof BannerNotificationComponent
   */
  public onActionClick(notification: IntelNotification) {
    this.store.dispatch(
      NotificationCenterActions.openNotificationActionPageOrUrl({
        notification,
      }),
    );
  }
}
