/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalSize } from '@dpa/ui-common/model';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { UserAdminAccountActions, UserAdminAccountSelector, UserPreferenceActions, UserPreferenceSelectors } from '@ws1c/intelligence-core';
import { RequestStatusType } from '@ws1c/intelligence-models';

/**
 * RequestCloudAccountModalComponent
 * @export
 * @class RequestCloudAccountModalComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-request-cloud-account-modal',
  templateUrl: 'request-cloud-account-modal.component.html',
})
export class RequestCloudAccountModalComponent implements OnInit, OnDestroy {
  public showRequestCloudAccountModal$: Observable<boolean>;
  public isCspAccountRequestAlreadySubmitted: boolean;
  public subscription: Subscription = new Subscription();
  public readonly ModalSize = ModalSize;

  /**
   * Creates instance of RequestCloudAccountModalComponent
   * @param {Store<MerlotState>} store - Merlot Store observable instance
   * @memberof RequestCloudAccountModalComponent
   */
  constructor(private store: Store<MerlotState>) {
    this.showRequestCloudAccountModal$ = this.store.select(UserPreferenceSelectors.showRequestCloudAccountModal);
  }

  /**
   * ngOnInit
   * Registers listeners for getCspAccountRequestStatus
   * @memberof RequestCloudAccountModalComponent
   */
  public ngOnInit(): void {
    this.store.dispatch(UserAdminAccountActions.getCspAccountRequest());
    this.subscription.add(
      this.store.select(UserAdminAccountSelector.getCspAccountRequestStatus).subscribe((cspAccountRequestStatus: RequestStatusType) => {
        this.isCspAccountRequestAlreadySubmitted = [RequestStatusType.GRANTED, RequestStatusType.REQUESTED].includes(
          cspAccountRequestStatus,
        );
      }),
    );
  }

  /**
   * Unsubscribes from the subscription
   * @memberof RequestCloudAccountModalComponent
   */
  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Dispatches action to close request cloud account modal
   * @memberof RequestCloudAccountModalComponent
   */
  public closeRequestAccountModal() {
    this.store.dispatch(UserPreferenceActions.setRequestCloudAccountModalOpenState({ isOpen: false }));
  }
}
